document.addEventListener("DOMContentLoaded", function () {
    const accordions = document.querySelectorAll('.accordion');
    if (accordions.length) {
        accordions.forEach(accordion => new Accordion(accordion));

        document.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                accordions.forEach(acc => acc.dispatchEvent(new Event('close')));
                if(document.querySelector('.filter--overlay.show')) {
                    document.querySelector('.filter--overlay.show').click();
                }
            }
         
        });
    }
});

function getFullHeight(element) {
    const computedStyle = window.getComputedStyle(element);
    const props = ['marginTop', 'marginBottom', 'paddingTop', 'paddingBottom'];
    const peripheralsHeightArray = props.map(prop => (parseInt(computedStyle[prop]) || 0));
    const fullHeight = [...peripheralsHeightArray, element.offsetHeight];
    return fullHeight.reduce((a, b) => a + b, 0);
}


const setStateOfNavMenuAccordions = (toggle) => {
    const wrapper = document.querySelectorAll('.nav_menu-toggle--wrapper');
    Array.from(wrapper).map(el => el.classList.remove('disabled'));

    if (toggle.classList.contains('active')) {

        Array.from(wrapper)
            .filter(el => el != toggle.closest('.nav_menu-toggle--wrapper'))
            .forEach(el => {
                el.classList.add('disabled');
                // accordion that is the element sibling
                
                const accordion = el.parentElement.querySelector('.accordion');
                accordion?.dispatchEvent(new Event('close')); 
            });

    }
}

// const closeFromOutside = (element) => {
//     document.addEventListener('click', function (e) {
//         // console.log('closing from outside');
//         // console.log('e.target: ', e.target);
//         // console.log('element: ', element);
//         if (element.contains(e.target)) return;

//         element.dispatchEvent(new Event('close'));
//         document.removeEventListener('click', arguments.callee);
//     });
// }

class Accordion {

    isOpen = false;
    
    constructor(element) {
        this.element = element;
        this.startHeight = this.element.offsetHeight + 'px';
        this.toggles = document.querySelectorAll(`[data-href="#${element.id}"]`);

        this.nestedFocalPoints = [...this.element.querySelectorAll('a, button, input, textarea, label.btn')].filter(el => el.closest('.accordion') == this.element);


        this.toggles.forEach(toggle => {
            if (this.element.classList.contains('truncation')) {
                this.element.classList.add('show');
            }
            toggle.addEventListener('click', (ev) => {
                //if(ev.isTrusted) {
                if (this.element.classList.contains('truncation')) {
                    // // console.log('truncation click');
                    this.element.querySelector('.accordion-body').style['-webkit-line-clamp'] = 'unset';
                    this.element.querySelector('.accordion-body').style['max-height'] = 'unset';
                    this.element.querySelector('.accordion-body').classList.toggle('show-all');
                    if(!this.element.querySelector('.accordion-body').classList.contains('show-all')) {
                        this.element.style.maxHeight = '';
                        setTimeout(() => {
                            this.element.querySelector('.accordion-body').removeAttribute('style');
                        }, 500);
                    }
                    toggle.querySelector('.prompt').textContent = this.isOpen ? 'Read more' : 'Read less';
                }
                this.isOpen ? this.close() : this.open();
                this.cleanUp(toggle, ev);
                //}
                //this.closeFromOutside(toggle);
            });
        });
        if (this.element.classList.contains('truncation')) {
            const body = this.element.querySelector('.accordion-body');
        
            if (body.scrollHeight == body.offsetHeight) 
                this.toggles.forEach(toggle => toggle.setAttribute('style', 'display: none'));
            
        }
        this.element.addEventListener('open', () => this.open());
        this.element.addEventListener('close', () => this.close());
        // console.log('about to concealFocalPoints', this.element);
        this.concealFocalPoints();

        if (this.element.classList.contains('click-off-to-close')) 
            //click.stopPropagation();
            this.closeFromOutside();
        
        

    }

    cleanUp = (toggle, click) => {
        
        
        // if(toggle.id == 'login-icon') {
        //     setTimeout(() => {
        //         document.querySelector('#login-menu input').focus();
        //     }, 300);
        //     document.querySelector('#login-menu input').focus();
        // }
        // if (toggle.id == 'search-icon') {
        //     document.querySelector('#global-search').focus();
        // }
        if (toggle.id == 'menu-icon') {
            setTimeout(() => {
                document.querySelector('#global-search').blur();
                // document.querySelector('#dropdown-menu a').focus();
                setTimeout(() => {
                    document.querySelector('#dropdown-menu a').focus();
                }, 500);
            }, 500);
            // document.querySelector('#dropdown-menu a').focus();
        }
        if (toggle.closest('.nav_menu-toggle--wrapper')) {
            setStateOfNavMenuAccordions(toggle);
        }
    }

    open = () => {
        this._open();
        const closestAccordion = this.element.parentElement.closest('.accordion');
        // console.log(this.element.scrollHeight);
        // console.log({closestAccordion, element: this.element});
        if (closestAccordion) {
            const currentMaxHeight = parseInt(closestAccordion.style.maxHeight);
            closestAccordion.style.maxHeight = currentMaxHeight + this.element.scrollHeight + 'px';
        }
        
        if (this.element.classList.contains('overlay-toggle')) {
            document.querySelector('.nav_menu-overlay').classList.add('open');
            document.querySelector('body').classList.add('fixed');
        }
        
       setTimeout(() => this.element.querySelector('.open-me')?.click(), 250);
    }


    _open = () => {
        this.toggles.forEach(toggle => {
            toggle.classList.add('active');
            if(toggle.dataset.href) {
                document.querySelector(toggle.dataset.href).classList.add('show');
                const targetForm = document.querySelector(toggle.dataset.href).querySelector('form.active');
                console.log('targetForm: ', targetForm);
                if(targetForm) {
                    if(toggle.id == 'login-icon' || toggle.id == 'search-icon') {
                        setTimeout(() => {
                            targetForm.querySelector('input').focus();
                        }, 300);
                    }
                    if (toggle.id == 'menu-icon') {
                        
                        document.querySelector('#dropdown-menu a').focus();
                    }
                    // targetForm.querySelector('input').focus();
                    // console.log('_open navMenuOpen: ', navMenuOpen);
                }
            }
        });
        this.revealFocalPoints();
        // // console.log('this.element: ', this.element);
        this.isOpen = true;
        

        
        this.animateAccordion();
    }

    close = () => {
        this._close();

        this.element.classList.remove('overflow');

        if (this.element.querySelector('.nav_menu-toggle--wrapper') || this.element.closest('.nav_menu-toggle--wrapper')) {
            const navMenuAccords =  document.querySelectorAll('.nav_menu-toggle--wrapper');
            navMenuAccords.forEach((acc) => {
                acc.classList.remove('disabled');

                if(acc.querySelector('.accordion-toggle')?.classList.contains('active')) {
                    acc.querySelector('.accordion-toggle').click();
                }
            });
        }
        if (this.element.classList.contains('overlay-toggle')) {
            document.querySelector('body').classList.remove('fixed');
            document.querySelector('.nav_menu-overlay').classList.remove('open');
        }
    }


    _close = () => {
        this.toggles.forEach(toggle => {
            toggle.classList.remove('active');

        });
        this.isOpen = false;
        this.concealFocalPoints();
        this.animateAccordion();
        
        // this.nestedAccordions.forEach(acc => acc.dispatchEvent(new Event('close')));
    }

    closeFromOutside = () => {
        document.addEventListener('click', (e) => {

            if (!this.isOpen) return;
            
            if (this.element.contains(e.target) || this.element == e.target) return;

            const doesAnyToggleContainTarget = [...this.toggles].some(toggle => toggle.contains(e.target));

            if (doesAnyToggleContainTarget) return;
    
            this.element.dispatchEvent(new Event('close'));
            // document.removeEventListener('click', arguments.callee);
        });
    }

    concealFocalPoints = () => {
        
        this.nestedFocalPoints.forEach(el => el.setAttribute('tabindex', '-1'));
    }

    revealFocalPoints = () => this.nestedFocalPoints.forEach(el => el.removeAttribute('tabindex'));

    animateAccordion = () => {
        const elChildrenNatHeight = Array.from(this.element.children)
            .map(child => getFullHeight(child))
            .reduce((a, b) => a + b, 0) + 'px';

        // console.log('elChildrenNatHeight: ', elChildrenNatHeight);
        
        // console.log('animateAccordion this.element: ', this.element);
        // console.log('animateAccordion this.startHeight: ', this.startHeight);

        this.element.style.maxHeight = this.isOpen ? elChildrenNatHeight : this.startHeight;
    }
}