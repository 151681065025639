updatePendingStatus = (isPending, loadMore) => {
    const loader = document.querySelector('.loader');
    const loadMoreButton = document.querySelector('.list-item--loadmore-wrapper');

    if(isPending) {
        loader.classList.add('show');
        loadMoreButton.style.display = 'none';
        return;
    }

    loader.classList.remove('show');

    if(loadMore) {
        loadMoreButton.style.display = 'block';
    }
}

if(document.querySelectorAll('.load-more').length) {
    document.querySelectorAll('.load-more').forEach(el => {
        el.addEventListener('click', () => {
            let nextPage = el.dataset.nextpage;
            let url = location.href.split('/page')[0] + '/page/' + nextPage;
            let loadmoreBody = el.closest('.load-more--module').querySelector('.load-more--body');
            let loadmoreBtnWrapper = el.closest('.list-item--loadmore-wrapper');
            
            updatePendingStatus(true, true);

            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // console.log('data: ', data);
                
                if(data.html) {
                    //let loadmoreHeight = loadmoreBody.offsetHeight + loadmoreBody.offsetTop;
                    

                    if(loadmoreBody.classList.contains('masonry')) {
                        masonry.forEach(el => {
                            if(el.element.id == loadmoreBody.id) {
                                el.element.insertAdjacentHTML('beforeend', data.html);
                                // el.appended( data.html );
    
                                const loadMoreImages = el.element.querySelectorAll('.list-item img');
                                const loadMoreImagePromises = Array.from(loadMoreImages).map(image => {
                                    return new Promise((resolve, reject) => {
                                        const img = new Image();
                                        img.onload = resolve;
                                        img.onerror = reject;
                                        img.src = image.src;
                                    });
                                });
    
                                // Wait for all images to load
                                Promise.all(loadMoreImagePromises).then(() => {
                                    // get the scroll position of the user
                                    const scrollPosition = window.scrollY;

                                    // console.log('image promise');
                                    el.layout();
                                    window.dispatchEvent(new Event('resize'));
                                    window.scrollTo(0, scrollPosition);
                                    el.element.querySelectorAll('.list-item').forEach(listItem => {
                                        listItem.classList.add('show');
                                    });
                                    updatePendingStatus(false, data.load_more);
                                    
                                }).catch(error => {
                                    console.error('Error loading images:', error);
                                    const scrollPosition = window.scrollY;

                                    // console.log('image promise');
                                    el.layout();
                                    window.dispatchEvent(new Event('resize'));
                                    window.scrollTo(0, scrollPosition);
                                    el.element.querySelectorAll('.list-item').forEach(listItem => {
                                        listItem.classList.add('show');
                                    });
                                    updatePendingStatus(false, data.load_more);

                                });
                            }
                        });
                    } else {
                        loadmoreBody.insertAdjacentHTML('beforeend', data.html);
                        updatePendingStatus(false, data.load_more);
                    }

                    history.pushState({}, "", url);
                    userFav();
                    
                    if(!data.load_more) {
                        loadmoreBtnWrapper.style.display = 'none';
                        document.querySelector('.loader').style.display = 'none';
    
                    } else {
                        loadmoreBtnWrapper.querySelector('.load-more').dataset.nextpage = Number(nextPage) +1;
                    }
                }
                
                if(!data.load_more) {
                    loadmoreBtnWrapper.style.display = 'none';
                    document.querySelector('.loader').style.display = 'none';

                }
                
                // console.log('list item length: ', document.querySelectorAll('.list-module--body .list-item').length);
            })
            .catch(error => {
                // console.log('error: ', error);
                updatePendingStatus(false);
            });
        });
    });
}

if(document.querySelectorAll('.load-more-gallery').length) {
    document.querySelectorAll('.load-more-gallery').forEach(el => {
        el.addEventListener('click', () => {
            const loadmore = el;
            const loadmoreBody = loadmore.closest('.list-module').querySelector('.load-more--body');

            updatePendingStatus(true, true);

            loadmoreBody.querySelectorAll('.list-item--none').forEach(el => {
                el.classList.remove('list-item--none');
                el.classList.add('list-item');
            });

            const loadMoreImages = loadmoreBody.querySelectorAll('.list-item img');
            const loadMoreImagePromises = Array.from(loadMoreImages).map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image.src;
                });
            });

            // Wait for all images to load
            Promise.all(loadMoreImagePromises).then(() => {
                // get the scroll position of the user
                const scrollPosition = window.scrollY;

                window.dispatchEvent(new Event('resize'));
                window.scrollTo(0, scrollPosition);
                loadmoreBody.querySelectorAll('.list-item').forEach(listItem => {
                    listItem.classList.add('show');
                });
                updatePendingStatus(false, false);
                
            }).catch(error => {
                console.error('Error loading images:', error);
                const scrollPosition = window.scrollY;

                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    window.scrollTo(0, scrollPosition);
                    loadmoreBody.querySelectorAll('.list-item').forEach(listItem => {
                        listItem.classList.add('show');
                    });
                    updatePendingStatus(false, false);
                }, 3000);
            });
            
        });
    });
}