const moveSwiperByStep = (swiper, { prev, next }, step) => {
    const moveSliderForward = (event, isReversed) => {
        event.preventDefault();

        const { activeIndex, slides } = swiper;

        const isOverflow = isReversed
            ? (activeIndex - step) < 0
            : (activeIndex + step) >= slides.length

        if (isOverflow)
            return swiper.slideTo(isReversed ? slides.length - 1 : 0);

        swiper.slideToLoop(isReversed ? activeIndex - step : activeIndex + step);
    };

    document.querySelectorAll(prev).forEach(toggle => toggle.addEventListener("click", (ev) => moveSliderForward(ev, false)));
    document.querySelectorAll(next).forEach(toggle => toggle.addEventListener("click", (ev) => moveSliderForward(ev, true)));
};

// moveSwiperByStep(lightboxSwiper, {
//     prev: ".object-lightbox--nav-prev",
//     next: ".object-lightbox--nav-next",
// }, slidesInView - 1);

document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector('.home-banner-slider')) {
        const homeBannerSwiper = new Swiper('.home-banner-slider', {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.home-banner-nav-next',
                prevEl: '.home-banner-nav-prev',
            },
            speed: 900,
            spaceBetween: 200,
            autoplay: {
                delay: 7000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            }
        });
    }

    if (document.querySelector('.object-header__image-swiper')) {
        let isSlider = false;
        let slideGap = 0;
        let slidesPerView = 0;

        if(window.innerWidth < 1080) {
            isSlider = document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').length > 1 ? true : false;
            slideGap = 10;
            slidesPerView = 3;
        } else {
            isSlider = document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').length > 1 ? true : false;
            slideGap = 15;
            slidesPerView = 5;
        }
        
        if(isSlider) {
            document.querySelector('.object-header__image').classList.remove('no-slider');
            let navHeight = 0;
            let headerImageHeight = document.querySelector('.object-header__image-main').offsetHeight;
            setTimeout(() => {
                document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').forEach(el => {
                    let imageWidth = el.querySelector('img').offsetWidth;
                    el.style.width = imageWidth+'px';
                });
                
                const objectHeaderSwiper = new Swiper('.object-header__image-swiper', {
                    slidesPerView: 'auto',
                    loop: false,
                    slideToClickedSlide: true,
                    height: 400,
                    breakpoints: {
                        1080: {
                            // slidesPerView: 5,
                            // slidesPerView: 'auto',
                            direction: 'vertical',
                        }
                    }
                });
    
                document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').forEach(el => {
                    navHeight += el.querySelector('img').clientHeight;
                });
                // let slideHeight = Number(document.querySelector('.object-header__image-swiper .object-header__image-swiper-slide').style.height.split('px')[0]);
                let slideHeight = document.querySelector('.object-header__image-swiper .object-header__image-swiper-slide').offsetHeight;
                if(navHeight > headerImageHeight + slideHeight) {
                    // navHeight = navHeight - document.querySelector('.object-header__image-main').offsetHeight + (slideGap * slidesPerView);
                    // navHeight = headerImageHeight + slideHeight;
                    navHeight = document.querySelector('.object-header__image-swiper--wrapper').offsetHeight - headerImageHeight + (slideGap * slidesPerView);
                }
    
                const objectHeader = document.querySelector('.object-header__image-main');
                const objectHeaderImage = objectHeader.querySelector('img');
        
                const setObjectHeaderImage = (image, index) => {
                    objectHeaderImage.src = image.src;
                    objectHeaderImage.alt = image.alt;
                    objectHeaderImage.dataset.caption = image.dataset.caption || '';
                    
                    objectHeader.setAttribute('data-slider-ref', index);
                }
    
                objectHeaderSwiper.on('beforeInit', (objectHeaderSwiper) => {
                    document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').forEach(el => {
                        const images = el.querySelectorAll('img');
                        const imagePromises = Array.from(images).map(image => {
                            return new Promise((resolve, reject) => {
                                const img = new Image();
                                img.onload = resolve;
                                img.onerror = reject;
                            });
                        });
                        // Wait for all images to load
                        Promise.all(imagePromises).then(() => {
                            let imageWidth = el.querySelector('img').offsetWidth;
                            el.style.width = imageWidth+'px';
                            // el.offsetWidth = imageWidth;
                            // objectHeaderSlider();
                        }).catch(error => {
                            console.error('Error loading images:', error);
                            setTimeout(() => {
                                let imageWidth = window.offsetWidth / 2;
                                el.style.width = imageWidth+'px';
                                // objectHeaderSlider();
                            }, 1000);
                        });
            
                        setTimeout(() => {
                            let imageWidth = window.offsetWidth / 2;
                            el.style.width = imageWidth+'px';
                        }, 3000);
                    });
                });

                document.querySelector('.object-header__image-nav--loading').classList.add('hide');
                document.querySelector('.object-header__image-swiper').classList.remove('hide');
        
                // clicking the slide image will change the main image
                objectHeaderSwiper.on('slideChange', () => {
                    if(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1]) {
                        if(objectHeaderSwiper.touches.diff < 0 && window.innerWidth >= 1080) {
                            let transformY = Number(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1].split('px')[0]);
                            if(transformY <= navHeight) {
                                document.querySelector('.object-header__image-swiper--wrapper').style.transform = 'translate3d(0px, -'+navHeight+'px, 0px)';
                            } else {
                                if(Math.abs(transformY) > navHeight) {
                                    document.querySelector('.object-header__image-swiper--wrapper').style.transform = 'translate3d(0px, -'+navHeight+'px, 0px)';
                                }
                            }
                        }
                    }

                    currentIndex = objectHeaderSwiper.activeIndex;
                    changedSlide = objectHeaderSwiper.slides[currentIndex];
                    slideImage = changedSlide.querySelector('img');
                    setObjectHeaderImage(slideImage, currentIndex);
                });

                document.querySelector('.object-header__image-swiper--wrapper').style.transform = 'translate3d(0px, 0px, 0px)';
                objectHeaderSwiper.el.addEventListener('mousedown', (event) => {
                    // Record the starting position of the mouse
                    let startX = event.pageX;
                    let startY = event.pageY;
    
                    // Set a flag to indicate that the mouse is currently dragging
                    let isDragging = true;
    
                    // Listen for mousemove event
                    objectHeaderSwiper.el.addEventListener('mousemove', function(event) {
                        // If the mouse is dragging, calculate the difference in position
                        if (isDragging) {
                            let deltaX = event.pageX - startX;
                            let deltaY = event.pageY - startY;
    
                            if(Math.abs(deltaY) > 0) {
                                if(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1]) {
                                    if(objectHeaderSwiper.touches.diff < 0 && window.innerWidth >= 1080) {
                                        let transformY = Number(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1].split('px')[0]);
        
                                        if(Math.abs(transformY) > navHeight) {
                                            document.querySelector('.object-header__image-swiper--wrapper').style.transform = 'translate3d(0px, -'+navHeight+'px, 0px)';
                                        }
                                    }
                                }
                            }
    
                            // Update the starting position of the mouse
                            startX = event.pageX;
                            startY = event.pageY;
                        }
                    });
                });
        
                document.querySelectorAll('.object-header__image-swiper-slide').forEach((slide, index) => {
                    slide.addEventListener('click', () => {
                        setObjectHeaderImage(slide.querySelector('img'), index);
                        if(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1]) {
                            let transformY = Number(document.querySelector('.object-header__image-swiper--wrapper').style.transform.split(', ')[1].split('px')[0]);
                            navHeight = document.querySelector('.object-header__image-swiper--wrapper').offsetHeight - headerImageHeight + (slideGap * slidesPerView);
                            if(Math.abs(transformY) >= navHeight || transformY == 0) {
                                document.querySelector('.object-header__image-swiper--wrapper').style.transform = 'translate3d(0px, -'+navHeight+'px, 0px)';
                            }
                        }
                    });
                });
            }, 2000);
        } else {
            document.querySelector('.object-header__image').classList.add('no-slider');
            document.querySelectorAll('.object-header__image-swiper .object-header__image-swiper-slide').forEach(el => {
                let imageWidth = el.querySelector('img').offsetWidth;
                el.style.width = imageWidth+'px';

                setTimeout(() => {
                    document.querySelector('.object-header__image-nav--loading').classList.add('hide');
                    document.querySelector('.object-header__image-swiper').classList.remove('hide');
                }, 3000);
            });
        }
    }

    if (document.querySelectorAll('.carousel-contents').length) {
        const carousels = [];

        document.querySelectorAll('.carousel-contents').forEach((el, index) => {
            const id = el.closest('.carousel').id;
            const prevBtn = el.closest('.carousel').querySelector('.carousel-arrow--prev');
            const nextBtn = el.closest('.carousel').querySelector('.carousel-arrow--next');
            const targetCarousel = document.getElementById(id);

            let slidesPerView = 1;
            let spaceBetween = 40;
            if (el.classList.contains('carousel-coloums-3')) {
                slidesPerView = 3;
            } else if (el.classList.contains('carousel-coloums-4')) {
                slidesPerView = 4;
            }

            const paginationEl = targetCarousel.querySelector('.swiper-pagination');

            const swiper = new Swiper(targetCarousel, {
                // loop: true,
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20,
                pagination: {
                    el: paginationEl,
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        return '<span class="swiper-pagination-current">' + current + '</span>/<span class="swiper-pagination-total">' + total + '</span>';
                    }
                },
                // Navigation arrows
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                },
                breakpoints: {
                    769: {
                        slidesPerView: slidesPerView,
                        slidesPerGroup: slidesPerView,
                        spaceBetween: spaceBetween,
                    },
                },
            });

            carousels.push(swiper);
        });
    }

    document.querySelectorAll('.carousel-tab .carousel-tab--item').forEach(el => {
        el.addEventListener('click', () => {
            const targetTab = el.dataset.tab;

            document.querySelectorAll('.carousel-tab .carousel-tab--item').forEach(el => {
                if (el.dataset.tab == targetTab) {
                    el.classList.add('active');
                } else {
                    el.classList.remove('active');
                }
            });

            document.querySelectorAll('.carousel-tab-module .carousel').forEach(el => {
                el.classList.remove('active');
                if (el.id == targetTab) {
                    el.classList.add('active');
                    // el.querySelector('.swiper-wrapper').classList.add('blur');
                }
                setTimeout(() => {
                    el.querySelector('.swiper-wrapper').classList.remove('blur');
                }, 100);
            });
        });
    });

    if(document.querySelectorAll(".object-lightbox-toggle").length) {
        document.querySelectorAll(".object-lightbox-toggle").forEach(el => {
            el.addEventListener('click', () => {
                objectLightbox(el);
            });
        });
    }
});

let lightboxSwiper;

function objectLightbox(toggle) {
    const objectLightboxToggle = toggle;
    const objectLightbox = document.querySelector('.object-lightbox');
    const objectLightboxCanvas = objectLightbox.querySelector(".zoom-target--wrapper");
    const objectLightboxCounter = objectLightbox.querySelector(".object-lightbox__control--info--counter");
    let slideAnchor = '';
    let caption = '';

    // Get slide length
    let slideLength = document.querySelectorAll('.object-header__image-swiper img').length;

    // Show Light box
    objectLightbox.classList.toggle("show");
    DesigateAccessible(true, objectLightbox);

    if (objectLightboxToggle.classList.contains('object-header__image-overlay')) {
        // Get target slide
        let targetSlide = document.querySelector('.object-header__image-main');

        // Get target slide number
        let targetSlideNumber = Number(targetSlide.dataset.sliderRef) + 1;


        document.querySelectorAll('.object-lightbox__slider .swiper-slide').forEach(el => {
            el.classList.remove('selected-slide');

            if(el.dataset.id == targetSlideNumber-1) {
                // console.log('add selected slide')
                el.classList.add('selected-slide');
            }
        });

        slideAnchor = objectLightboxToggle.closest('.object-header__image-main');

        objectLightboxCanvas.querySelector('.zoom-target').src = slideAnchor.querySelector('img').src;

        caption = document.querySelectorAll('.object-header__image-swiper img')[targetSlide.dataset.sliderRef]?.dataset?.caption || '';

        objectLightboxCounter.textContent = document.querySelector('.object-header__image-swiper-slide.swiper-slide[aria-label="'+targetSlideNumber+' / '+slideLength+'"]')?.ariaLabel || '';
    } else {
        if(objectLightboxToggle.dataset.sliderRef == 0) {
            
            objectLightboxCanvas.querySelector('.zoom-target').src = document.querySelectorAll('.object-header__image-swiper img')[0].src;
            caption = document.querySelectorAll('.object-header__image-swiper img')[0].dataset.caption;
            objectLightboxCounter.textContent = document.querySelectorAll('.object-header__image-swiper-slide')[0].ariaLabel;

            document.querySelectorAll('.object-lightbox__slider .swiper-slide').forEach(el => {
                el.classList.remove('selected-slide');
                if(el.ariaLabel = '1 / '+slideLength) {
                    el.classList.add('selected-slide');
                }
            });
        }
    }
    
    const objectLightboxCaption = objectLightbox.querySelector(".object-lightbox__control--info--title");
    objectLightboxCaption.innerHTML = caption;
    
    const isSlider = objectLightbox.querySelectorAll('.object-lightbox__slider-wrapper img').length;
    const closeLightbox = objectLightbox.querySelector('.object-lightbox .close');

    closeLightbox.addEventListener("click", () => {
        objectLightbox.classList.remove("show");
        objectLightbox.querySelector('.zoom-target').style = '';
        DesigateAccessible(false, objectLightbox);
    });

    loadZoomOntoLightboxImage(objectLightboxCanvas);

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            objectLightbox.classList.remove('show');
        }
    });
    

    let slidesInView = Math.round(window.innerWidth / 125);
    let slidesCount = objectLightbox.querySelectorAll('.swiper-slide').length;
    // let slidesPerView = slidesInView < slidesCount ? slidesInView : 'auto';
    // let isLoopingAllowed = slidesCount > slidesInView * 2;

    if(slidesCount > 1) {
        lightboxSwiper = new Swiper('.object-lightbox__slider', {
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 20,
            variableWidth: true,
            freeMode: true,
            // watchSlidesVisibility: true,
            // watchSlidesProgress: true,
            // observer: true,
            // observeParents: true,
            // loop: true,
            // slidesPerView: 4,
            // centeredSlides: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.object-lightbox .object-lightbox--nav-next',
                prevEl: '.object-lightbox .object-lightbox--nav-prev',
            },
            breakpoints: {
                1080: {
                    spaceBetween: 40,
                   
                }
            }
        });

        // navigation: {
            // nextEl: '.object-lightbox .lightbox-arrow--next',
            // prevEl: '.object-lightbox .lightbox-arrow--prev',
        // },
        
        lightboxSwiper.update();
    
        // if (objectLightbox.querySelectorAll('.object-lightbox__slider--nav').length) {
        //     const panNavigation = objectLightbox.querySelector('.object-lightbox__slider--nav');
        //     // console.table({ slidesInView, slidesCount })
        //     // if (slidesInView > slidesCount + 1)  {
        //     //     panNavigation.classList.add('hidden');
        //     // }
        // }

        let lightboxSliderWidth = 0;
        document.querySelectorAll('.object-lightbox__slider--track .swiper-slide ').forEach(el => {
            let slideSpace = 40;
            if(window.innerWidth < 1080) {
                slideSpace = 20;
            }
            lightboxSliderWidth += (el.clientWidth + slideSpace);
        });


        if(window.innerWidth * 0.85  > lightboxSliderWidth) {
            objectLightbox.querySelector('.object-lightbox__slider--nav').classList.add('hidden');
        } else {
            objectLightbox.querySelector('.object-lightbox__slider--nav').classList.remove('hidden');
        }

        if(objectLightbox.querySelectorAll(".object-lightbox .swiper-slide").length) {
            objectLightbox.querySelectorAll(".object-lightbox .swiper-slide").forEach(el => {
                el.addEventListener('click', () => {
                    let targetSlide = el.ariaLabel;
                    setLightboxFocus(el);
                    lightboxSwiper.update();
                    document.querySelector('.object-header__image-swiper .swiper-slide[aria-label="'+targetSlide+'"]').click()
                });
            })
        }
    
        
        lightboxSwiper.on('slideChange', function () {
            // lightboxSwiper sometimes has no slides property \_(-_-)_/
            const slides = objectLightbox.querySelectorAll(".object-lightbox .swiper-slide");
            const slide = slides[lightboxSwiper.activeIndex];
    
            setLightboxFocus(slide);
            lightboxSwiper.update();

            const activeSlide = document.querySelector('.object-lightbox__slider .selected-slide');

            // TODO: Why is an item outside of slide being clicked?
            // document.querySelector('.object-header__image-swiper-slide[aria-label="'+activeSlide.ariaLabel+'"]').click();
        });
    
    
        const setLightboxFocus = (slide) => {
            slideImage = slide.querySelector("img");

            if(objectLightbox.querySelectorAll(".object-lightbox .swiper-slide").length) {
                objectLightbox.querySelectorAll(".object-lightbox .swiper-slide").forEach(el => {
                    el.classList.remove('selected-slide');
                    if(el == slide) {
                        el.classList.add('selected-slide');
                    }
                });
            }
    
            const progressionCounter = slide.getAttribute("aria-label"),
                slideCaption = slideImage.getAttribute("data-caption"),
                srcImage = slideImage.dataset.lightboxSrc;
            
            objectLightboxCanvas.querySelector(".zoom-target").setAttribute("src", srcImage);
            objectLightboxCaption.innerHTML = slideCaption;
            objectLightboxCounter.innerHTML = progressionCounter;
            loadZoomOntoLightboxImage(objectLightboxCanvas);
        };
    
        if(window.innerWidth >= 1080) {
            moveSwiperByStep(lightboxSwiper, {
                prev: ".object-lightbox--nav-prev",
                next: ".object-lightbox--nav-next",
            }, slidesInView - 1);
        }
    }
    
    if (!isSlider) return;
    let imgStartX, currentImg;

    objectLightboxCanvas.addEventListener('touchstart', function (e) {
        imgStartX = e.touches[0].clientX;
        currentImg = this.querySelector(".zoom-target");
    });

    objectLightboxCanvas.addEventListener('touchmove', function (e) {

        if (e.target !== currentImg || currentImg.classList.contains('zooming')) return;

        const deltaX = e.touches[0].clientX - imgStartX;

        requestAnimationFrame(() => {
            currentImg.style.transform = `translate(calc(-50% + ${deltaX}px), -50%)`;
        });
    });

    objectLightboxCanvas.addEventListener('touchend', function (e) {
        if (e.target !== currentImg || currentImg.classList.contains('zooming')) return;

        const endX = e.changedTouches[0].clientX;

        const isOverScrollThreshold = (imgStartX - endX) ** 2 > (window.innerWidth / 4) ** 2;

        if (isOverScrollThreshold) {
            const direction = imgStartX - endX > 0 ? 1 : -1;
            currentImg.animate([
                { transform: currentImg.style.transform }, { transform: `translate(calc(-50% + ${direction * -100}vw), -50%)` }
            ], { duration: 500, easing: 'ease-in-out' })
                .onfinish = () => {
                    console.log('finidh')
                    direction > 0 ? lightboxSwiper.slideNext() : lightboxSwiper.slidePrev();
                    currentImg = this.querySelector(".zoom-target");
                    currentImg.animate([
                        { transform: `translate(calc(-50% + ${direction * 100}vw), -50%)` }, { transform: `translate(-50%, -50%)` }
                    ], {
                        duration: 500, easing: 'ease-in-out'
                    }).onfinish = () => {
                        currentImg.style.transform = `translate(-50%, -50%)`;
                    }
                };

            return;
        }
        currentImg.animate([
            { transform: currentImg.style.transform },
            { transform: `translate(-50%, -50%)` }
        ], {
            duration: 500,
            easing: 'ease-in-out'
        }).onfinish = () => {
            currentImg.style.transform = `translate(-50%, -50%)`;
        }

    });
}

const nextSlideMethod = (e) => {
    const lightBox = document.querySelector('.object-lightbox__slider');
    let slidesCount = lightBox.querySelectorAll('.swiper-slide').length;

    e.preventDefault();
    const activeSlide = document.querySelector('.object-lightbox__slider .selected-slide');
    let activeIndex = activeSlide.dataset.id;

    if(activeIndex < slidesCount - 1) {
        let nextId = parseInt(activeIndex) + 1;

        lightboxSwiper.slideTo(activeIndex);
        document.querySelector(".swiper-slide[data-id='"+ nextId +"']").click()
    }
}

function prevSlideMethod(e){
    e.preventDefault();

    
    const activeSlide = document.querySelector('.object-lightbox__slider .selected-slide');
    let activeIndex = activeSlide.dataset.id;

    if(activeIndex > 0) {
        let prevId = parseInt(activeIndex) - 1;

        lightboxSwiper.slideTo(prevId);
        document.querySelector(".swiper-slide[data-id='"+ prevId +"']").click()
    }
}

if(document.getElementById('slide-next-button')){
    document.getElementById('slide-next-button').addEventListener('click', nextSlideMethod)
}

if(document.getElementById('slide-prev-button')){
    document.getElementById('slide-prev-button').addEventListener('click', prevSlideMethod)
}


function loadZoomOntoLightboxImage(canvas) {
    // clear canvas listeners for wheel, mousedown, mousemove, mouseup, mouseleave
    canvas.innerHTML = canvas.innerHTML;

    const img = canvas.querySelector('.zoom-target');
    let scale = 1;
    const baseScaleFactor = 0.1;
    let mouseX = 0;
    let mouseY = 0;
    let dragging = false;
    let dragStartX, dragStartY;
    let totalDeltaX = 0; // Total movement in x direction
    let totalDeltaY = 0;

    function applyZoom(scaleFactor, lateral = false) {

        img.classList.add('zooming');

        scale += scaleFactor;
        scale = Math.max(1, scale);

        if (scale === 1) img.classList.remove('zooming');

        img.style.transform = `translate(-50%, -50%) scale(${scale})`;

        if (lateral && !(scale === 1)) return;

        const rect = img.getBoundingClientRect();
        const offsetX = scale == 1 ? .5 : (mouseX - rect.left) / rect.width;
        const offsetY = scale == 1 ? .5 : (mouseY - rect.top) / rect.height;

        img.style.transformOrigin = `${offsetX * 100}% ${offsetY * 100}%`;

    }


    const lateralZoom = (scaleFactor) => {
        mouseX = window.innerWidth / 2;
        mouseY = window.innerHeight / 2;

        applyZoom(scaleFactor * 5, true);
    };

    const dragStart = (e) => {
        if (scale > 1) {
            dragging = true;
            dragStartX = e.clientX || e.touches[0].clientX;
            dragStartY = e.clientY || e.touches[0].clientY;
        }
    }

    const dragMove = (e) => {
        if (dragging) {
            e.preventDefault();

            const touchpoint = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY };
            const deltaX = touchpoint.x - dragStartX;
            const deltaY = touchpoint.y - dragStartY;

            img.style.transform = `translate(calc(-50% + ${totalDeltaX + deltaX}px), calc(-50% + ${totalDeltaY + deltaY}px)) scale(${scale})`;
        }
    }
    const dragEnd = (e) => {
        if (dragging) {
            dragging = false;
            const endX = e.clientX || e.changedTouches[0].clientX;
            const endY = e.clientY || e.changedTouches[0].clientY;
            totalDeltaX += endX - dragStartX;
            totalDeltaY += endY - dragStartY;
        }
    }

    canvas.addEventListener('wheel', (e) => {
        e.preventDefault();

        mouseX = e.clientX;
        mouseY = e.clientY;

        const scaleFactor = e.deltaY < 0 ? baseScaleFactor : -baseScaleFactor;

        applyZoom(scaleFactor);
    });



    const zoomInToggles = document.querySelectorAll(".object-lightbox__control--zoom-in"),
        zoomOutToggles = document.querySelectorAll(".object-lightbox__control--zoom-out");


    zoomInToggles.forEach((toggle) => {
        toggle.addEventListener("click", () => lateralZoom(baseScaleFactor));
    });

    zoomOutToggles.forEach((toggle) => {
        toggle.addEventListener("click", () => lateralZoom(-baseScaleFactor));
    });

    document.addEventListener('keydown', (e) => {
        if (e.key === '+' || e.key === '=')
            lateralZoom(baseScaleFactor);
        if (e.key === '-' || e.key === '_')
            lateralZoom(-baseScaleFactor);
    });



    canvas.addEventListener('mousedown', e => dragStart(e));

    canvas.addEventListener('mousemove', e => dragMove(e));



    canvas.addEventListener('mouseup', e => dragEnd(e));
    canvas.addEventListener('mouseleave', e => dragEnd(e));

    let initialPinchDistance, initialScale;


    // Add touch event listeners for pinch-to-zoom functionality
    canvas.addEventListener('touchstart', (e) => {
        if (e.touches.length === 2) {
            e.preventDefault();
            initialPinchDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            );
            initialScale = scale;
            mouseX = (e.touches[0].pageX + e.touches[1].pageX) / 2;

            // rather than using the page height touchpoint, use the relative touchpoint
            mouseY = (e.touches[0].pageY + e.touches[1].pageY) / 2 - window.pageYOffset;

            e.preventDefault();
        } else if (e.touches.length === 1) {
            dragStart(e);
        }
    });


    canvas.addEventListener('touchmove', (e) => {
        if (scale > 1) e.preventDefault();
        if (e.touches.length === 2) {
            e.preventDefault();
            const currentPinchDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            );

            const scaleFactor = (currentPinchDistance / initialPinchDistance) - 1;

            scale = initialScale;
            applyZoom(scaleFactor);
        } else if (e.touches.length === 1) {
            dragMove(e);
        }
    });

    canvas.addEventListener('touchend', (e) => {
        // if (e.touches.length < 2) {
        initialPinchDistance = null;
        initialScale = null;
        // } else if (e.touches.length === 1) {
        dragEnd(e);

    });
}
