let masonry = []; // Store Masonry instances
// console.log('masonry.js');
window.addEventListener('DOMContentLoaded', function () {
    if(document.querySelectorAll('.masonry').length) {
        document.querySelectorAll('.masonry').forEach((el, index) => {
            let id = el.id;
            let gutter = el.querySelector('.gutter-sizer').clientWidth;
            let masonryFlag = true;

            if(window.innerWidth < 769 && el.classList.contains('list-module--col-2')) {
                masonryFlag = false;
            }
            // // console.log('id: ', id);
            // // console.log('gutter: ', gutter);
            if(masonryFlag) {
                // Fetch images using the fetch API
                const images = el.querySelectorAll('.list-item img');
                const imagePromises = Array.from(images).map(image => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = resolve;
                        img.onerror = reject;
                        img.src = image.src;
                    });
                });

                // console.log('imagePromises: ', imagePromises);

                // Wait for all images to load
                Promise.all(imagePromises).then(() => {
                    masonry[index] = new Masonry(document.getElementById(id), {
                    // options
                        itemSelector: '.list-item',
                        gutter: gutter
                    });
                    document.querySelectorAll('.masonry .list-item').forEach(listItem => {
                        listItem.classList.add('show');
                    });
                }).catch(error => {
                    console.error('Error loading images:', error);
                    setTimeout(() => {
                        masonry[index] = new Masonry(document.getElementById(id), {
                        // options
                            itemSelector: '.list-item',
                            gutter: gutter
                        });
                        document.querySelectorAll('.masonry .list-item').forEach(listItem => {
                            listItem.classList.add('show');
                        });
                    }, 3000);
                });
            }
        });
    }
});

window.addEventListener('resize', () => {
    masonryRedo(masonry);
});

function masonryRedo(masonry) {
    masonry.forEach(masonryInstance => {
        masonryInstance.destroy(); // Destroy existing Masonry instance
    });
    masonry = []; // Clear the array
  
    // Reinitialize Masonry instances
    document.querySelectorAll('.masonry').forEach((el, index) => {
        let id = el.id;
        let gutter = el.querySelector('.gutter-sizer').clientWidth;
        let masonryFlag = true;

        if(window.innerWidth < 769 && el.classList.contains('list-module--col-2')) {
            masonryFlag = false;
        }

        if(masonryFlag) {
            masonry[index] = new Masonry(document.getElementById(id), {
                // options
                itemSelector: '.list-item',
                gutter: gutter
            });
    
            // Reload Masonry layout after reinitializing
            masonry[index].layout();
        }
    });
}