let timestamp;


document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelectorAll('.search-keyword').length) {
        document.querySelectorAll('.search-keyword').forEach(el => {
            let form = el.closest('form');
            if(form.querySelector('.autocomplete')) {
                el.addEventListener('keyup', (e) => {
                    // console.log('e.code: ', e.code);
                    if(e.code === 'Enter') {
                        e.preventDefault();
                        if(e.target.closest('.search-keyword')) {
                            basicSearch(form);
                        }
                    } else {
                        autocompleteCall(el);
                    }
                });
                el.addEventListener('click', () => {
                    autocompleteCall(el);
                });
                form.querySelector('.search-icon').addEventListener('click', () => {
                    basicSearch(form);
                });
            } else {
                el.addEventListener('keyup', (e) => {
                    if(e.code === 'Enter') {
                        e.preventDefault();
    
                        form.submit();
                    }
                });
                form.querySelector('.search-icon').addEventListener('click', () => {
                    form.submit();
                });
            }
        });
    }

    if(document.querySelectorAll('.filter-mobile .search-icon').length) {
        document.querySelectorAll('.filter-mobile .search-icon').forEach(el => {
            let mobileSearchForm = el.closest('form');
            el.addEventListener('click', () => {
                basicSearch(mobileSearchForm);
            });
        });
    }

    if(document.querySelectorAll('.filter').length) {
        document.querySelectorAll('.filter').forEach(el => {
            const overlay = document.createElement('div');
            overlay.classList.add('filter--overlay');
            el.appendChild(overlay);
        });
    }

    if (document.querySelectorAll('.filter-btn').length) {
        document.querySelectorAll('.filter-btn').forEach(el => {
            el.addEventListener('click', (ev) => {
                collectionFilter(el);
                ev.stopPropagation();
            });
        });
    }

    if(document.querySelectorAll('.filter-modal--list-item .adv-search-item').length) {
        document.querySelectorAll('.filter-modal--list-item .adv-search-item').forEach(el => {
            el.addEventListener('click', () => {
                timestamp = new Date().getTime();
                let advField = el.dataset.field;
                let advValue = el.dataset.value;

                document.querySelectorAll('form.search').forEach(el => {
                    let form = el;
                    let input = document.createElement('input');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', advField);
                    input.setAttribute('value', advValue);
                    input.setAttribute('class', 'advanced-search');
                    form.appendChild(input);
                    // console.log('form: ', form);
                });

                let formName = '';
                if(window.innerWidth < 1080) {
                    let formName = '.mobile';
                }

                let targetForm = document.querySelector('form.search'+formName);

                targetForm.querySelector('.search-keyword').value = '';

                basicSearch(targetForm);
            });
        });
    }

    if (document.querySelectorAll('.sort-list').length) {
        document.querySelectorAll('.sort-list').forEach(el => {
            // el.classList.remove('hide-none');
            if (el.closest('.open-calendar--wrapper')) {
                el.dataset.originalHeight = 310;
            } else {
                let originalHeight = el.offsetHeight;
                el.dataset.originalHeight = originalHeight;
            }

            el.classList.add('hide');
            DesigateAccessible(false, el);
        });
    }

    if(document.querySelectorAll('.sort-list-item--text.active').length) {
        document.querySelectorAll('.sort-list-item--text.active').forEach(el => {
            const dropdown = el.closest('.dropdown');
            // setTimeout(() => {
                if(dropdown) {
                    dropdown.querySelector('.filter-sort--text').innerText = el.querySelector('span').textContent;
                    dropdown.querySelector('.filter-sort--text').dataset.value = el.dataset.type;
                }
            // }, 500);
        });
    }

    if(document.querySelectorAll('.filter-mobile--selection-list .filter-mobile--selection-item').length) {
        document.querySelectorAll('.filter-mobile--selection-list .filter-mobile--selection-item').forEach(el => {
            let currentMobileFilter = el.closest('.filter-mobile');
            let currentModal = el.closest('.filter-mobile--selection-list');
            el.addEventListener('click', () => {
                let currentTap = currentModal.dataset.tab;
                if(currentTap == 'collection-fields') {
                    currentMobileFilter.querySelector('.filter-sort').dataset.value = el.querySelector('.sort-list-item--text').dataset.type;
                    currentMobileFilter.querySelector('.filter-sort span').innerText = el.querySelector('.sort-list-item--text').innerText;
                    currentMobileFilter.querySelector('.filter-mobile .btn-close.active').click();

                }
            });
        });
    }

    if (document.querySelectorAll('.filter-modal .btn-filter-close').length) {
        document.querySelectorAll('.filter-modal .btn-filter-close').forEach(el => {
            el.addEventListener('click', () => {
                let targetFilter = el.dataset.id;
                document.querySelector('.filter-modal--content[data-value="' + targetFilter + '"]').classList.remove('show');
            });
        });
    }

    document.querySelector('body').addEventListener('click', (e) => {
        let target = e.target;

        if (!target.closest('.search')) {
            autocompleteClose(target);
        }
        if (target.closest('.filter-sort')) {
            dropdownCall(target);
        } else {
            if (!target.closest('.dropdown') && !target.classList.contains('btn-shortcut')) {
                dropdownClose(target);
            }
        }
        if (!target.closest('.filter-btn') && !target.closest('.filter-modal')) {
            collectionFilterClose();
        }
        if (target.closest('#search-filter .sort-list-item')){
            applyFilter(target);
        }
    });
    
    document.addEventListener('keydown', (e) => {
        // console.log('e.key: ', e.key);
        if (e.key === 'Escape') {
            filterBtn = document.querySelector('.filter-btn.active');
            collectionFilterClose();
            if (filterBtn) {
                filterBtn.focus();
            }
        }
        if (e.key === 'Tab') {
            allDropdowns = document.querySelectorAll('.dropdown');
            allSortLists = document.querySelectorAll('.sort-list');
            allFilterModals = document.querySelectorAll('.filter-modal--content');
            // console.log('e.target: ', e.target);
            // console.log('e.target sort-list: ', e.target.closest('.sort-list'));
            if(document.querySelectorAll('.sort-list.show').length && !e.target.closest('.sort-list.show') && !e.target.closest('.sort-selected')) {
                // console.log('not inside sort list')
                if(document.querySelector('.filter--overlay.show')) {
                    //document.querySelector('.filter--overlay.show').click();

                }
            } else {
                // console.log('inside sort list')
            }
        }
        if(e.key === 'Enter') {

        }
    });
    // document.querySelectorAll('a, button').forEach(el => {
    //     el.addEventListener('keydown', (e) => {
    //         if (e.key === 'Escape') {
    //             filterBtn = document.querySelector('.filter-btn.active');
    //             collectionFilterClose();
    //             if (filterBtn) {
    //                 filterBtn.focus();
    //             }
    //         }
    //         if (e.key === 'Tab') {
    //             allDropdowns = document.querySelectorAll('.dropdown');
    //             allSortLists = document.querySelectorAll('.sort-list');
    //             allFilterModals = document.querySelectorAll('.filter-modal--content');
    //             // console.log('e.target: ', e.target);
    //             // console.log('e.target sort-list: ', e.target.closest('.sort-list'));
    //             if(document.querySelectorAll('.sort-list.show').length && !e.target.closest('.sort-list.show')) {
    //                 if(document.querySelector('.filter--overlay.show')) {
    //                     document.querySelector('.filter--overlay.show').click();
    //                 }
    //             }
    //             // if (allDropdowns.length || allSortLists.length || allFilterModals.length) {
    //             //     [...allDropdowns, ...allSortLists, ...allFilterModals].forEach(el => {
    //             //         // console.log('all blar el: ', el);
    //             //         el.classList.remove('show');
    //             //         el.classList.add('hide');
    //             //         // if(document.querySelectorAll('.sort-list.show').length) {
    //             //         //     if(document.querySelector('.filter--overlay.show')) {
    //             //         //         document.querySelector('.filter--overlay.show').click();
    //             //         //     }
    //             //         // }
    //             //     });
    //             // }
    //             // closestDropdown = el.closest('.dropdown') || el.closest('.filter-modal--content');
    //             // if (closestDropdown) {
    //             //     closestDropdown.classList.remove('hide');
    //             //     closestDropdown.classList.add('show');
    //             // }
    //             // closesSortList = el.closest('.sort-list');
    //             // if (closesSortList) {
    //             //     closesSortList.classList.remove('hide');
    //             //     closesSortList.classList.add('show');
    //             // }
    //         }
    //         if(e.key === 'Enter') {
    //             closestDropdown = el.closest('.dropdown') || el.closest('.filter-modal--content');
    //             // console.log('Enter el: ', el);
    //             if(document.querySelectorAll('.sort-list.show').length) {
    //                 let list = document.querySelector('.sort-list.show');
    //                 // console.log('list a,button:', list.querySelector('a, button'));
    //                 list.querySelector('a, button').focus();
    //             }
    //             // if(closestDropdown.classList.contains('show')) {
    //             //     closestDropdown.querySelector('a, button').focus();
    //             // }
    //         }
    //     });
    // });
    const overflowXFilter = document.querySelector('.filter-overflow-x');

    if (overflowXFilter) {
        const [scrollLeft, scrollRight] = [...document.querySelectorAll('.filter-overflow-x-button')];
        const wrapper = overflowXFilter.parentElement;

        const setWrapperWidth = () => {
            const offset = wrapper.closest('.filter-top').querySelector('.filter-top--right').scrollWidth;
            console.log('offset: ', offset);
            wrapper.style.maxWidth = `calc(100% - ${offset}px)`;
        },
        
        updateScrollButtons = () => {
            scrollLeft.style.display = 'none';
            scrollRight.style.display = 'none';
            overflowXFilter.classList.remove('left');
            overflowXFilter.classList.remove('right');
            
            if (overflowXFilter.scrollLeft > 0) {
                scrollLeft.style.display = 'block';
                scrollLeft.style.visibility = 'visible';
                overflowXFilter.classList.add('left');
            }

            if (overflowXFilter.scrollWidth - overflowXFilter.scrollLeft > overflowXFilter.clientWidth) {
                scrollRight.style.display = 'block';
                scrollRight.style.visibility = 'visible';
                overflowXFilter.classList.add('right');
            }
        }

        overflowXFilter.addEventListener('scroll', updateScrollButtons);

        window.addEventListener('resize', () => {
            updateScrollButtons();
            setWrapperWidth();
        });
        setWrapperWidth();

        setTimeout(updateScrollButtons, 1000);
        
        scrollLeft.addEventListener('click', () => overflowXFilter.scrollTo({left: Math.max(0, overflowXFilter.scrollLeft - 150), behavior: 'smooth'}));
        scrollRight.addEventListener('click', () => overflowXFilter.scrollTo({left: overflowXFilter.scrollLeft + 150, behavior: 'smooth'}));
            
    }
});

function basicSearch(form) {
    console.log('form: ', form);
    const url = form.getAttribute('action');
    let currentFilter;
    let filterType;
    let keyword;

    if(form.classList.contains('mobile')) {
        currentFilter = document.querySelector('.filter-mobile .filter-sort');
        filterType = currentFilter?.dataset.value;
        keyword = form.querySelector('.search-keyword').value;
    } else {
        currentFilter = form.closest('.filter');
        filterType = currentFilter.querySelector('.filter-sort--text')?.dataset.value;
        keyword = currentFilter.querySelector('.search-keyword').value;
    }

    let formData = new FormData();

    formData.append('year_from', document.querySelector('#yearFrom')?.value);
    formData.append('year_to', document.querySelector('#yearTo')?.value);

    if(form.querySelectorAll('.advanced-search').length) {
        form.querySelectorAll('.advanced-search').forEach(el => {
            formData.append(el.name, el.value);
        });
    }

    if(document.querySelectorAll('.filter-criteria--remove').length) {
        document.querySelectorAll('.filter-criteria--remove').forEach(el => {
            let field = el.dataset.key;

            if(field) {
                let value = el.dataset.value;
                // formData.append(field, value);

                //not from keyword
                if(field.indexOf('ac-') > -1) {
                    formData.append('advanced_field[]', field);
                    formData.append('advanced_value[]', value);
                } else {
                    // //from keyword
                   
                    if(field !== filterType) {
                        formData.append('advanced_field[]', field);
                        formData.append('advanced_value[]', value);
                    } else {
                        if(!keyword) { //no keyword
                            if(form.querySelectorAll('.advanced-search').length) {
                                formData.append('advanced_field[]', field);
                                formData.append('advanced_value[]', value);
                            }
                        }
                    }
                }
            }
        });
    }

    if(keyword && filterType) {
        formData.append('advanced_field[]', filterType);
        formData.append('advanced_value[]', keyword);
    }

    if(!form.querySelectorAll('.advanced-search').length && !document.querySelectorAll('.filter-criteria--remove').length && !keyword) {
        formData.append('advanced_field[]', filterType);
        formData.append('advanced_value[]', keyword);
    }
    

    // formData.append('advanced_field[]', filterType);
    // formData.append('advanced_type[]', '');
    // formData.append('advanced_value[]', keyword);
    

    

    console.log('timestamp: ', new Date().getTime() - timestamp);
    timestamp = new Date().getTime();

    fetch(url, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: formData
    })
    .then((response) => {
        console.log('second timestamp: ', new Date().getTime() - timestamp);
        

        if (response.redirected) {
            window.location.href = response.url;
        }
    })
    .catch(error => {
        // console.log('error: ', error);
    });
}

function autocompleteCall(searchKeyword) {
    let search = searchKeyword;
    let searchWrapper = search.closest('.search');

    if (search.value.length > 2) {
        autocomplete(search.value, searchWrapper);
    } else {
        searchWrapper.classList.remove('show');
    }
    dropdownClose();
}

function autocomplete(keyword, searchWrapper) {
    const url = document.querySelector('.autocomplete').dataset.autocompleteUrl;
    let autocomplete = searchWrapper.querySelector('.autocomplete');
    let formData = new FormData();
    
    formData.append('keyword', keyword);
    // console.log('autocomplete url: ', url);
    // console.log('autocomplete: ', autocomplete);
    fetch(url, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: formData
    })
    .then((response) => {
        return response.json();
    })
    .then(data => {
        let autocompleteList = '';
        Object.keys(data.aggregations).forEach(key => {
            let agg = data.aggregations[key];
            // // console.log('agg: ', agg);
            // // console.log('agg.doc_count: ', agg.doc_count);
            if(agg.doc_count > 0) {
                autocompleteList += '<ul>';
                autocompleteList += '<li class="autocomplete-group">';
                autocompleteList += '<span class="autocomplete-group--title">'+agg.name+'</span>';
                autocompleteList += '<ul class="autocomplete-list">';
                // console.log('agg.results.buckets: ', agg.results.buckets);
                Object.keys(agg.results.buckets).forEach(bucketKey => {
                    // console.log('bucketKey: ', bucketKey);
                    if(bucketKey < 3) {
                        let bucket = agg.results.buckets[bucketKey];
                        // console.log('bucket: ', bucket);
                        autocompleteList += '<li class="autocomplete-list--item">';
                        autocompleteList += '<button type="button" class="btn-no-border list-item--link" data-type="ac-'+key+'" data-value="'+bucket.key+'" title="go to page">'+bucket.label+'</button>';
                        autocompleteList += '<span class="autocomplete-list--title">'+bucket.label+'</span>';
                        autocompleteList += '<span class="autocomplete-list--number">'+bucket.doc_count+' artworks</span>';
                    } else if(bucketKey == 3) {
                        autocompleteList += '<li class="autocomplete-list--item autocomplete-list--viewall">';
                        autocompleteList += '<button type="button" class="btn-no-border list-item--link" data-type="ac-auto-'+key+'" data-value="'+keyword+'" title="go to page">link</button>';
                        autocompleteList += '<span class="autocomplete-list--viewall-text">View all results</span>';
                    }
                });
                autocompleteList += '</ul>';
                autocompleteList += '</li>';
                autocompleteList += '</ul>';
            }
        });
        autocomplete.innerHTML = autocompleteList;

        console.log('autocomplete: ', autocomplete)
        
        searchWrapper.classList.add('show');
        let originalHeight = autocomplete.offsetHeight;
        autocomplete.dataset.maxHeight = originalHeight;
        autocomplete.classList.add('hide');

        //autocomplete.style.maxHeight = originalHeight;
        autocomplete.classList.remove('hide');

        if (document.querySelectorAll('.dropdown .sort-list.show').length) {
            document.querySelectorAll('.dropdown .sort-list.show').forEach(el => {
                el.classList.remove('show');
            });
        }

        autocompleteSearch();
    }) 
    .catch(error => {
        console.log('error: ', error);
    })
    
}

function autocompleteSearch() {
    // console.log('autocompleteSearch called');
    if(document.querySelectorAll('.list-item--link').length) {
        document.querySelectorAll('.list-item--link').forEach(el => {
            el.addEventListener('click', () => {
                // console.log('el: ', el);
                const url = document.querySelector('form.search').action;
                let autoType = el.dataset.type;
                let autoValue = el.dataset.value;
                let formData = new FormData();

                formData.append(autoType, autoValue);

                fetch(url, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: formData
                })
                .then((response) => {
                    if (response.redirected) {
                        window.location.href = response.url;
                    }
                })
                .catch(error => {
                    // console.log('error: ', error);
                });
            });
        });
    }
}

function autocompleteClose(target) {
    let targetSearch = target.closest('.search.show');
    if (!targetSearch) {
        if (document.querySelectorAll('.search.show').length) {
            document.querySelectorAll('.search.show').forEach(el => {
                el.classList.remove('show');
            });
        }
    } else {
        document.querySelectorAll('.search.show').forEach(el => {
            if (el !== targetSearch) {
                el.classList.remove('show');
            }
        });
    }
}

function dropdownCall(target) {
    let currentWrapper = target.closest('.dropdown');
    if (document.querySelectorAll('.dropdown').length) {
        let isAnyDropdownOpeningTheOverlay = false;
        document.querySelectorAll('.dropdown').forEach(el => {
            const overlay = el.closest('.filter')?.querySelector('.filter--overlay');

            
           
            if (!isAnyDropdownOpeningTheOverlay) overlay?.classList.remove('show');

            console.log({el});
            if (el == currentWrapper) {
                let originalHeight = currentWrapper.querySelector('.sort-list').dataset.originalHeight;
                currentWrapper.querySelector('.sort-list').style.maxHeight = originalHeight + 'px';
                console.log({el}, 'sort-list if top');

                if (currentWrapper.querySelector('.sort-list').classList.contains('hide')) {
                    currentWrapper.querySelector('.sort-list').classList.remove('hide');
                    currentWrapper.querySelector('.sort-list').classList.add('show');

                    DesigateAccessible(true, currentWrapper.querySelector('.sort-list'));

                    currentWrapper.classList.add('show');


                    overlay?.classList.add('show');
                    
                    isAnyDropdownOpeningTheOverlay = true;
                } else {
                    console.log('else top', currentWrapper.querySelector('.sort-list'));
                    currentWrapper.querySelector('.sort-list').classList.add('hide');
                    currentWrapper.querySelector('.sort-list').classList.remove('show');
                    currentWrapper.classList.remove('show');

                    DesigateAccessible(false, currentWrapper.querySelector('.sort-list'));
                }
                // if(currentWrapper.querySelector('.sort-list').classList.contains('show')) {
                //     currentWrapper.querySelector('.sort-list').removeAttribute('style');
                // }
            } else {
                console.log(el.querySelector('.sort-list'), 'sort-list else bottom');
                el.querySelector('.sort-list').classList.remove('show');
                el.querySelector('.sort-list').classList.add('hide');
                DesigateAccessible(false, el.querySelector('.sort-list'));

            }
        });
    }
}

function applyFilter(target) {
    const dropdown = target.closest('.dropdown');
    const filterSortBtn = dropdown.querySelector('.filter-sort--text');
    const filterButtons = dropdown.querySelectorAll('.sort-list-item .sort-list-item--text');
    const newTextValue = target.innerText;
    const targetForm = dropdown.closest('.filter-collection').querySelector('form');

    filterSortBtn.innerText = newTextValue;
    filterSortBtn.dataset.value = target.closest('.sort-list-item--text').dataset.type;
    filterButtons.forEach(el => el.classList.remove('active'));
    target.classList.add('active');
    
    dropdownClose();

    setTimeout(() => {
        let currentKeyword = targetForm.querySelector('.search-keyword');
        currentKeyword.focus();
        currentKeyword.setSelectionRange(currentKeyword.value.length, currentKeyword.value.length);
    }, 100);
}

function dropdownClose() {
    document.querySelectorAll('.dropdown .sort-list.show').forEach(el => {
        const overlay = el.closest('.filter')?.querySelector('.filter--overlay');
        overlay?.classList.remove('show');
        el.classList.remove('show');
        el.classList.add('hide');
        el.classList.add('hide-none');
        el.closest('.dropdown').classList.remove('show');
        DesigateAccessible(false, el);
    });
}


function collectionFilter(target) {
    let targetPanel = target.dataset.id;
    console.log({target});
    if (!target.classList.contains('btn-filter-open')) {
        target.closest('.filter-list').classList.toggle('modal-open');
        document.querySelectorAll('.filter-btn').forEach(el => {
            
            if (el == target) {
                el.classList.toggle('active');
            } else {
                el.classList.remove('active');
            }
        });
    }

    document.querySelectorAll('.filter-modal--content').forEach(el => {
        if (el.dataset.value == targetPanel) {
            const nearestFilter = el.closest('.filter');
            if (!target.classList.contains('btn-filter-open')) {
                el.classList.toggle('show');
                
                
                if (el.scrollHeight < screen.height/2) {
                    el.classList.add('no-scrollbar');
                }
            } else {
                el.classList.add('show');
            }
            // setTimeout(() => {
            //     el.scrollTop = 0;
            // }, 600);
            DesigateAccessible(true, el);
            
            if (el.classList.contains('show')) { 
                focusFirstLink(el);
            }
        } else {
            el.classList.remove('show');
            DesigateAccessible(false, el);
        }
    });
}

function collectionFilterClose() {
    document.querySelectorAll('.filter-list').forEach(el => {
        el.classList.remove('modal-open');
    });
    document.querySelectorAll('.filter-btn').forEach(el => {
        
        el.classList.remove('active');
    });
    document.querySelectorAll('.filter-modal--content').forEach(el => {
        el.classList.remove('show');
        DesigateAccessible(false, el);
    });
}

function yearSearch() {
    let formName = '';
    if(window.innerWidth < 1080) {
        let formName = '.mobile';
    }
    basicSearch(document.querySelector('form.search'+formName));
}
function focusFirstLink(el) {
    
    const elementIsVisible = (el) => {
        let computedStyle = window.getComputedStyle(el);
        return computedStyle.display !== 'none';
    }

    allTabbables = [...el.querySelectorAll('a, button, input')];


    allTabbables = allTabbables.filter(el => elementIsVisible(el));
    
    allTabbables[0].focus();
}