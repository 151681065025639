if(KT.environment !== 'development') {
    console.log = function () {};
}

document.addEventListener("DOMContentLoaded", function () {
    let dropdownOpen = false;
    let lightboxOpen = false;
    let navMenuOpen = false;
    let prevTab;
    let prevTabLightbox;

    window.addEventListener('keyup', (e) => {
        if (e.key === 'Tab') {
            document.querySelector('body').classList.remove('no-focus-outline');
            
            if(e.target.closest('.dropdown')) {
                console.log('inside dropdown navMenuOpen: ', navMenuOpen);
                if(!dropdownOpen && !navMenuOpen) {
                    prevTab = e.target;
                }
                dropdownOpen = true;

                if(!e.target.closest('.dropdown.show') && !prevTabLightbox) {
                    e.target.closest('.dropdown').parentElement?.click();
                    dropdownOpen = false;
                    // console.log('not .dropdown.show prevTab: ', prevTab);

                    // e.target.focus();
                    prevTab.focus();
                }
            } else {
                if(dropdownOpen) {
                    // console.log('prevTab.parentElement: ', prevTab.closest('.dropdown').parentElement);
                    prevTab.closest('.dropdown').parentElement?.click();
                    // document.querySelector('body:not(.dropdown)').click();
                }
                dropdownOpen = false;
            }

            if(e.target.closest('.nav_menu.show')) {
                // console.log('keyup tab nav_menu show');
                navMenuOpen = true;
            } else {
                if(navMenuOpen) {
                    if(!e.target.closest('.nav_menu.show')) {
                        document.querySelectorAll('.nav_menu').forEach(el => {
                            el.dispatchEvent(new Event('close'));
                        });

                        prevTab.focus();
                    }
                }

                navMenuOpen = false;
            }

            if(e.target.closest('.lightbox.show, .object-lightbox.show, .lightbox-single.show')) {
                lightboxOpen = true;
            } else {
                if(lightboxOpen) {
                    if(!e.target.closest('.lightbox-single.show')) {
                        document.querySelector('.show button.close')?.click();
                        if(prevTabLightbox) {
                            prevTabLightbox.focus();
                        } else {
                            prevTab.focus();
                        }
                    }
                    if(e.target.closest('.object-lightbox.show')) {

                    }
                }
                lightboxOpen = false;
            }

            if(!e.target.closest('.dropdown') && !e.target.closest('.nav_menu.show, .lightbox, .object-lightbox, .lightbox-single.show')) {
                prevTab = e.target;
            }
        }


        if (e.key === 'Enter') {
            if(e.target.closest('.lightbox.show, .object-lightbox.show, .lightbox-single.show')) {
                const activeLightbox = e.target.closest('.lightbox.show, .object-lightbox.show, .lightbox-single.show');
                if(activeLightbox.querySelectorAll('.dropdown').length) {
                    prevTabLightbox = prevTab;
                }
                // if(activeLightbox.classList.contains('object-lightbox')) {
                //     const activeSlide = document.querySelector('.object-lightbox__slider .swiper-slide-active');
                //     document.querySelector('.object-header__image-swiper-slide[aria-label="'+activeSlide.ariaLabel+'"]').click();
                // }
                // const activeSlide = document.querySelector('.object-lightbox__slider .swiper-slide-active');
                // document.querySelector('.object-header__image-swiper-slide[aria-label="'+activeSlide.ariaLabel+'"]').click();
            }
        }

        if(e.key === 'Escape') {
            if(e.target.closest('.lightbox.show, .lightbox-single.show')) {
                // document.querySelector('.show button.close')?.click();
                // lightboxOpen = false;
                // prevTab.focus();
                // if(e.target.closest('.object-lightbox.show')) {
                //     const activeSlide = document.querySelector('.object-lightbox__slider .swiper-slide-active');
                //     document.querySelector('.object-header__image-swiper-slide[aria-label="'+activeSlide.ariaLabel+'"]').click();
                // }
            }
        }
    });

    window.addEventListener('keydown', e => {
        if (e.key === 'Tab') {

            if(e.target.id == 'global-search') {
                e.target.closest('.nav_menu').querySelector('.close-dropdown').click();
                prevTab.focus();
            }
            
            if(e.target.closest('.lightbox.show, .object-lightbox.show, .lightbox-single.show') && e.target.closest('.dropdown')) {
            }
            
            if(e.target.closest('.social-wrapper li:last-of-type button')) {
                document.querySelector('#modal-social:not(.social-wrapper.show)').click();
            }
    
            if(e.target.closest('.lightbox-single.show button.close')) {
                document.querySelector('.show button.close')?.click();
                lightboxOpen = false;
                prevTab.focus();
            }
        }

        if (e.key === 'Enter') {
            if(e.target.closest('.close-dropdown.active')) {
                e.target.closest('.close-dropdown.active').click();
                let currentPanel = e.target.closest('.accordion');

                if(currentPanel.querySelectorAll('a, input, button').length) {
                    currentPanel.querySelectorAll('a, input, button').forEach(el => {
                        el.blur();
                    });
                }
            }
        }

        if(e.key === 'Escape') {
            if(e.target.closest('.lightbox.show, .lightbox-single.show')) {
                document.querySelector('.show button.close')?.click();
                lightboxOpen = false;
                prevTab?.focus();
            }
            if(document.querySelectorAll('.nav_menu.show').length) {
                document.querySelectorAll('.nav_menu.show').forEach(el => {
                    const currentPanel = el;
                    currentPanel.querySelector('.close-dropdown.active')?.click();
                    currentPanel.querySelectorAll('a, button, input').forEach(el => {
                        el.blur();
                    });
                    navMenuOpen = false;
                    prevTab.focus();
                });
            }
        }
    });

    if (document.querySelector('.to-top')) {
        document.querySelector('.to-top').addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }

    if (document.querySelectorAll('.truncation').length) {
        document.querySelectorAll('.truncation').forEach(el => {
            const truncationWrapper = el.parentElement;
            const truncation = el;
            if (truncation.classList.contains('.less')) {
                truncationWrapper.classList.remove('show-less');
                truncation.classList.add('less');
            } else {
                truncationWrapper.classList.add('show-less');
                truncation.classList.remove('less');
            }
        });
    }

    if (document.querySelectorAll('.side-image').length) {
        document.querySelectorAll('.side-image').forEach(el => {
            el.addEventListener('mouseover', () => {
                modalSideImage(el);
            });
        });
    }

    if (document.querySelectorAll('.btn-shortcut').length) {
        document.querySelectorAll('.btn-shortcut').forEach(el => {
            el.addEventListener('click', () => {
                const shortcutWrapper = el.closest('.shortcut-wrapper');
                const shortcutTarget = el.dataset.goto;
                const targetContent = shortcutWrapper.querySelector('[data-shortcut="' + shortcutTarget + '"]');

                if (targetContent) {
                    const targetPosition = targetContent.offsetTop;
                    targetContent.querySelectorAll('button')[0].focus();
                    shortcutWrapper.scrollTo({ top: targetPosition, behavior: 'smooth' })
                }
            });
        });
    }

    if (document.querySelectorAll('.copyURL').length) {
        document.querySelectorAll('.copyURL').forEach(el => {
            el.addEventListener('click', () => {
                copyURL(el);
            });
        });
    }

    if (document.querySelectorAll('.calendar').length) {
        document.querySelectorAll('.calendar').forEach(el => {
            calendar(el)
        });
    }

    if (document.querySelectorAll('.open-calendar').length) {
        document.querySelectorAll('.open-calendar').forEach(el => {
            el.addEventListener('click', () => {
                // console.log({el})
                el.closest('.input-group').querySelector('.sort-calendar button').click();
            });
        });
    }

    if(document.querySelectorAll('.play-cover').length) {
        document.querySelectorAll('.play-cover').forEach(el => {
            el.addEventListener('click', () => {
                const playWrapper = el.closest('.play-wrapper');
                playWrapper.classList.add('play');
                playWrapper.querySelector('video').play();
            });
        });
    }

    if(document.querySelector('.footnote')) {
        //const footnote = document.querySelector('.footnote');
        const nav = document.querySelector('.nav');
        const filter = document.querySelector('.filter--wrapper');

        const scrollToLink = (hRef) => {
            let stickyHeaderOffset = Math.max(nav.clientHeight, (filter?.clientHeight || 0));

            if (window.innerWidth < 1080) stickyHeaderOffset += 12;

            const target = document.querySelector(hRef);
            const offsetTop = target.offsetTop - stickyHeaderOffset;
            
            window.scrollTo({top: offsetTop, behavior: "smooth"});

        } 

        
        // check hash on load
        if(location.href.split('#')[1]) {
            // perfect time for self adjustment
            setTimeout(() => {
                const hash = location.href.split('#')[1];
                scrollToLink(`#${hash}`);
            }, 400);

        }
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', (e) => {
                e.preventDefault();
                const hRef = anchor.getAttribute('href');
                scrollToLink(hRef);
            });
        });
    }

    if(document.querySelectorAll('.chapter-anchor').length) {
        document.querySelectorAll('.chapter-anchor').forEach(el => {
            el.addEventListener('click', (evt) => {
                evt.preventDefault();
                const elTextContent = el.textContent;
                const target = el.href.split('#')[1].split(';')[0];
                
                
                // // console.log('target: ', target);
                anchorFinder(target);

                if(el.classList.contains('mobile')) {
                    el.closest('.filter-mobile--selection').querySelector('.btn-close').click();
                    document.querySelector('.filter-mobile .btn span').textContent = elTextContent;
                }
            });
        });
        
        document.addEventListener('scroll', () => {
            const activeAnchor = document.querySelector('.desktop-only .chapter-anchor.active');
        
            if(activeAnchor) {
                const nextAnchor = activeAnchor.parentElement.nextElementSibling?.querySelector('.chapter-anchor');
            
                if (nextAnchor) {
    
                    nextSection = document.querySelector('#' + nextAnchor.href.split('#')[1]);
                    nextSectionTop = nextSection.offsetTop - 90;
    
                    if (nextSectionTop < window.scrollY) {
                        activeAnchor.classList.remove('active');
                        nextAnchor.classList.add('active');
                        nextAnchorTextContent = nextAnchor.textContent;
                        document.querySelector('.filter-mobile .btn span').textContent = nextAnchorTextContent;
                        return;
                    }
                }
    
                const prevAnchor = activeAnchor.parentElement.previousElementSibling?.querySelector('.chapter-anchor');
    
                if (prevAnchor) {
    
                    const activeSection = document.querySelector('#' + activeAnchor.href.split('#')[1]);
                    const activeSectionTop = activeSection.offsetTop - 90;
    
                    if (activeSectionTop > window.scrollY) {
                        activeAnchor.classList.remove('active');
                        prevAnchor.classList.add('active');
                        prevAnchorTextContent = prevAnchor.textContent;
                        document.querySelector('.filter-mobile .btn span').textContent = prevAnchorTextContent;
                        return;
                    }
                }
            } else {
                let currentTop = '';
                document.querySelectorAll('.main-bodytext--chapter').forEach(el => {
                    let chapterTop = el.offsetTop;

                    if(chapterTop < window.scrollY + 90) {
                        currentTop = el.id;
                    }
                });

                if(currentTop) {
                    document.querySelectorAll('[href="#'+currentTop+'"]').forEach(el => {
                        el.classList.add('active');
                    });
                }
            }
        });
    }

    if(location.href.split('#')[1]?.split(';')[0]) {
        let target = location.href.split('#')[1]?.split(';')[0];

        anchorFinder(target);
    }

    function anchorFinder(target) {
        if(document.querySelector('#'+target)) {
            const targetEl = document.querySelector('#'+target);
            let targetTop = targetEl.offsetTop;

            const stickyFilter = document.querySelector('.filter--wrapper');
            let stickyFilterOffset = stickyFilter?.clientHeight || 0;

            // console.log('stickyFilterOffset: ', {stickyFilterOffset});
            stickyFilterOffset += stickyFilter?.classList.contains('fixed') 
                ? 0 
                : 25;

            const offsetTop = targetTop - stickyFilterOffset;
            let url = location.href.split('#')[0] + '#'+target;

            // console.log({stickyFilterOffset})

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });

            history.pushState({}, "", url);
        }
    }

    if(document.querySelectorAll('.lightbox-single-trigger').length) {
        document.querySelectorAll('.lightbox-single-trigger').forEach(el => {
            el.addEventListener('click', () => {
                const targetLightbox = document.querySelector('.lightbox-single');
                DesigateAccessible(true, targetLightbox);

                const type = el.dataset.type;
                const caption = el.dataset.caption || '';
                const viewall = el.dataset.itemLink;
                switch(type) {
                    case 'image':
                        const image = el.closest('.lightbox-item').querySelector('img').src;
                        targetLightbox.querySelector('img').src = image;
                        singleLightboxCanvas = targetLightbox.querySelector('.zoom-target--wrapper');
                        loadZoomOntoLightboxImage(singleLightboxCanvas);
                        break;
                    case 'video':
                        const video = el.dataset.media;
                        // console.log('video: ', video);
                        targetLightbox.querySelector('video source').src = video;
                        targetLightbox.querySelector('video').load();
                        targetLightbox.classList.add('media');
                        targetLightbox.classList.add('video');
                        break;
                    case 'audio':
                        const audio = el.dataset.media;
                        // console.log('audio: ', audio);
                        targetLightbox.querySelector('audio source').src = audio;
                        targetLightbox.querySelector('audio').load();
                        targetLightbox.classList.add('media');
                        targetLightbox.classList.add('audio');
                        break;
                    default:
                        break;
                }
                targetLightbox.querySelector('.lightbox-single--caption > span').innerHTML = caption;
                if(viewall) {
                    targetLightbox.querySelector('.lightbox-single--caption > a').style.display = 'inline-block';
                    targetLightbox.querySelector('.lightbox-single--caption > a').setAttribute('href', viewall);
                } else {
                    targetLightbox.querySelector('.lightbox-single--caption > a').style.display = 'none';
                }
                targetLightbox.classList.add('show');


                lightboxSingleClose();
            });
        });
    }

    function lightboxSingleClose() {
        const lightbox = document.querySelector('.lightbox-single');
        document.querySelector('.lightbox-single .close').addEventListener('click', () => {
            lightbox.classList.remove('media');
            lightbox.classList.remove('video');
            lightbox.classList.remove('audio');
            lightbox.classList.remove('show');
            lightbox.querySelector('.zoom-target').style = '';
            lightbox.querySelector('video').pause();
            lightbox.querySelector('audio').pause();
            DesigateAccessible(false, lightbox);
        });

        document.addEventListener('keydown', (e) => {
            // console.log('e.key: ', e.key);
            if (e.key === 'Escape') {
                lightbox.classList.remove('media');
                lightbox.classList.remove('video');
                lightbox.classList.remove('audio');
                lightbox.classList.remove('show');
                lightbox.querySelector('.zoom-target').style = '';
                lightbox.querySelector('video').pause();
                lightbox.querySelector('audio').pause();
                DesigateAccessible(false, lightbox);
            }
        });
    }
    
    document.querySelector('body').addEventListener('mouseover', (e) => {
        const target = e.target;

        if (!target.closest('.side-image')) {
            destroySideImage(target);
        }
    });

    if (document.querySelectorAll('.filter-mobile .btn-open').length) {
        document.querySelectorAll('.filter-mobile .btn-open').forEach(el => {
            el.addEventListener('click', () => {
                const targetTab = el.dataset.tab;
                const selectedFilter = el.closest('.filter-mobile');

                selectedFilter.classList.add('open');
                selectedFilter.querySelector('.btn-close[data-tab="' + targetTab + '"]').classList.add('active');
                selectedFilter.querySelector('.filter-mobile--selection-list[data-tab="' + targetTab + '"]').classList.add('show');
                closeFilterMobile(selectedFilter);
            });
        })
    }

    if(document.querySelectorAll('.nav_menu input').length && document.querySelectorAll('.nav_menu-overlay').length) {
        if(document.querySelector('.nav_menu-overlay')) {
            document.querySelectorAll('.nav_menu input').forEach(el => {
                el.addEventListener('keydown', (e) => {
                    // console.log('e.key: ', e.key);
                    if (e.key === 'Enter') {
                        const targetForm = el.closest('form');
                        // console.log({targetForm});
                        if(el.type != 'checkbox') {
                            targetForm.querySelector('.btn-submit').click();
                        } else {
                            el.checked = !el.checked;
                        }
                    } 
                });
            });
        } 
    }
    // console.log(document.querySelectorAll('.bodytext iframe'));
    if(document.querySelectorAll('.bodytext iframe').length && window.innerWidth > 1080) {
        const iframes = document.querySelectorAll('.bodytext iframe');
        iframes.forEach(iframe => {
            if (iframe.hasAttribute('width')) {
                const width = iframe.getAttribute('width');
                iframe.style.width = `${width}px`
            }
            if (iframe.hasAttribute('height')) {
                const height = iframe.getAttribute('height');
                iframe.style.height = `${height}px`
            }
        });

    }
    

    // Auth - register/forgot
    if(document.querySelectorAll('.nav_menu .btn-submit, .auth-register .btn-submit').length) {
        document.querySelectorAll('.nav_menu .btn-submit, .auth-register .btn-submit').forEach(el => {
            el.addEventListener('click', () => {
                let targetForm = el.closest('form');
                const url = targetForm.getAttribute('action');
                
                if(!el.classList.contains('btn-search')) {
                    targetForm.querySelectorAll('.form-input--error').forEach(el => {
                        el.textContent = '';
                    });
                    targetForm.querySelectorAll('.input-group.error').forEach(el => {
                        el.classList.remove('error');
                    });

                    if (targetForm.querySelector('.form-error'))
                        targetForm.querySelector('.form-error').textContent = '';

                    if (targetForm.querySelector('.auth-message'))
                        targetForm.querySelector('.auth-message').textContent = '';

                    if (targetForm.querySelector('input[name="g_recaptcha_site_key"]'))
                        applyGoogleReCAPTCHAAndSubmitForm(authFormSubmit, url, targetForm);
                    else 
                        authFormSubmit(url, targetForm);

                } else {
                    targetForm.submit();
                }
            });
        });
    }

    if(document.querySelectorAll('.modal .lightbox-close').length) {
        document.querySelectorAll('.modal .lightbox-close').forEach(el => {
            const targetModal = el.closest('.modal');
            const modalName = targetModal.id.split('modal-')[1];

            modalClose('lightbox', modalName);
        });
    }

    if(document.querySelectorAll('.form-builder .btn-cancel').length) {
        document.querySelectorAll('.form-builder .btn-cancel').forEach(el => {
            el.addEventListener('click', () => {
                const targetModal = el.closest('.modal');
                targetModal.querySelector('.lightbox-close').click();
            });
        });
    }

    if(document.querySelectorAll('.form-builder .btn-submit').length) {
        document.querySelectorAll('.form-builder .btn-submit').forEach(el => {
            el.addEventListener('click', () => {
                const targetForm = el.closest('form');
                const targetModal = el.closest('.modal');
                const formID = targetForm.dataset.formId;
                const url = KT.base_url + 'api/submit';

                targetForm.querySelectorAll('.form-input--error').forEach(el => {
                    el.textContent = '';
                });
                targetForm.querySelectorAll('.input-group.error').forEach(el => {
                    el.classList.remove('error');
                });
                targetForm.querySelector('.form-error').textContent = '';

                let formData = new FormData();
                formData.append('id', formID);
                targetForm.querySelectorAll('.input-group [name]').forEach(el => {
                    // console.log('el.name: ', el.name, ', el.value: ', el.value);
                    switch(el.type) {
                        case 'checkbox':
                            if(el.checked) {
                                if(formData.has('custom_form-'+el.name)) {
                                    let oriValue = formData.get('custom_form-'+el.name);
                                    formData.set('custom_form-'+el.name, oriValue+', '+el.value);
                                } else {
                                    formData.append('custom_form-'+el.name, el.value);
                                }
                            }
                            break;
                        case 'radio':
                            if(el.checked) {
                                formData.append('custom_form-'+el.name, el.value);
                            }
                            break;
                        default:
                            formData.append('custom_form-'+el.name, el.value);
                            break;
                    }
                });
                // formData.append('data', params);
                if(targetModal.id == 'modal-image-download') {
                    formData.append('image_id', document.querySelector('.object-header__image-main img').dataset.mediaId);
                }
    
                fetch(url, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: formData
                })
                .then((response) => {
                    return response.json();
                })
                .then(data => {
                    // console.log('data: ', data);
                    if(data.status == 0) {
                        targetForm.querySelector('.form-error').textContent = '* ' + data.message;

                        Object.keys(data.data).forEach(key => {
                            const errorList = data.data[key];
                            const field = targetForm.querySelector('[name="'+key+'"]');
                            // const fieldWrapper = field.closest('.input-group');
                            let fieldWrapper = field.closest('.input-group');
                            if(field.closest('.input-group--half')) {
                                fieldWrapper = field.closest('.input-group--half');
                            }

                            let errorOutput = fieldWrapper.querySelector('.form-input--error');;

                            if(key.split('_')[0] == 'checkbox') {
                                errorOutput = fieldWrapper.parentElement.querySelector('.form-input--error');
                            }
                            field.closest('.input-group').classList.add('error');
                            errorOutput.textContent = errorList;
                        });
                    } else if(data.status == 1) {
                        if(targetModal.id == 'modal-image-download') {
                            targetForm.querySelector('.btn-submit').textContent = data.message;
                            targetForm.querySelector('.btn-submit').setAttribute('disabled', '');
                            window.open(data.image_requested);
                            setTimeout(() => {
                                targetForm.querySelector('.lightbox-close').click();
                                targetForm.querySelector('.btn-submit').textContent = 'submit';
                                targetForm.querySelector('.btn-submit').removeAttribute('disabled');
                            }, 3000);
                        } else {
                            targetForm.querySelector('.btn-submit').textContent = 'Success';
                            targetForm.querySelector('.btn-submit').setAttribute('disabled', '');
                            setTimeout(() => {
                                targetForm.querySelector('.lightbox-close').click();
                                targetForm.querySelector('.btn-submit').textContent = 'submit';
                                targetForm.querySelector('.btn-submit').removeAttribute('disabled');
                            }, 3000);
                        }
                    }
                })
                .catch(error => {
                    // console.log('error: ', error);
                });
            })
        })
    } 
    
    if(document.querySelectorAll('.user-fav').length) {
        userFav();
    }

    if(document.querySelectorAll('.tooltip').length) {
        setTimeout(() => {
            document.querySelectorAll('.tooltip').forEach(el => {
                let position = el.getBoundingClientRect();
                if(position.x < 100) {
                    el.classList.add('tooltip-position--left');
                } else {
                    el.classList.remove('tooltip-position--left');
                }

                if(position.x > (window.innerWidth - 100) && position.x < window.innerWidth-25) {
                    el.classList.add('tooltip-position--right');
                } else {
                    el.classList.remove('tooltip-position--right');
                }
            });
        }, 300);
    }

    // let tabIndex = 0;
    // function setTabIndex(el) {
    //     el.setAttribute('tabindex', tabIndex);
    //     tabIndex++;
    // }
    // allTabbableElements = document.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // allTabbableElements.forEach(setTabIndex);

    // const lastNavbarIndex = document.querySelector('#dropdown-menu').getAttribute('tabindex');

    // // console.log(lastNavbarIndex);


    // add an even listener for the enter key, that clicks the currently focused element
        
        // if (e.key === "ArrowRight" && e.shiftKey && e.key === "Tab") {
        //     if (document.activeElement.getAttribute('tabindex') <= lastNavbarIndex) {
        //         e.preventDefault();
        //         document.querySelector('[tabindex="' + (lastNavbarIndex + 1) +'"]').focus();
        //     }
        // }
            
    

    const menuList = document.querySelector('#dropdown-menu-list'),
        closeIcon = menuList.querySelector('.close-dropdown'),
        // remove the close icon from the list
        menuItems = Array.from(menuList.children).slice(0, -1),

        splitMenuItems = splitThrice(menuItems),
        newMenuLists = splitMenuItems.map((items, i) => {
            const newList = document.createElement('ul');
            newList.classList.add('scripted-ui');
            items.forEach(item => newList.appendChild(item));
            return newList;
        });

    while (menuList.firstChild) menuList.removeChild(menuList.firstChild);

    newMenuLists.forEach(list => menuList.appendChild(list));
    menuList.appendChild(closeIcon);


    const loginMenuTabTriggers = document.querySelectorAll('.login-menu-tab');

    loginMenuTabTriggers.forEach(trigger => {
        trigger.addEventListener('click', function (ev) {
            ev.preventDefault();
            const href = this.getAttribute('href');
            const thisLoginForm = document.querySelector(href);
            const allLoginForms = document.querySelectorAll('.tab-form');

            const allLoginFormTabs = document.querySelectorAll('.tab');

            loginMenuTabTriggers.forEach(el => el.classList.remove('active'));

            [...allLoginFormTabs, ...allLoginForms].forEach(el => el.classList.remove('active'));

            trigger.classList.add('active');

            document.querySelector('.login-menu-tab--wrapper a[href="'+href+'"]')?.classList.add('active');
            thisLoginForm.classList.add('active');
            thisLoginForm.closest('.tab').classList.add('active');

            thisLoginForm.closest('.accordion').dispatchEvent(new Event('open'));
        });
    });

    const navbar = document.querySelector('.nav');

    const navbarTop = navbar.offsetTop - 20,
        header = document.querySelector('header');

    let navbarHeight = navbar.offsetHeight + (window.innerWidth > 768 ? 40 : 24);
        if(header.querySelector('.nav').classList.contains('small_logo')) {
            navbarHeight = navbarHeight + 15;
        }
        
    let filterTopOrigin = 0;
    let flag = 0;
    if(document.querySelectorAll('.filter--wrapper').length) {
        filterTopOrigin = document.querySelector('.filter--wrapper').offsetTop;
        // console.log({filterTopOrigin});
        flag = 1;
    }
    document.addEventListener('scroll', function () {
        if(filterTopOrigin > 0) {
            if(window.scrollY > 0) {
                filterTopOrigin = document.querySelector('.filter--wrapper').offsetTop;
            }
            

            if(navbarTop < window.scrollY) {
                if(flag) {
                    navbar.classList.add('sticky');
                    header.style.marginBottom = navbarHeight + 'px';
                    flag = 0;
                }
                if(filterTopOrigin - (navbar.offsetHeight - navbarTop) < window.scrollY) {
                    //document.querySelector('.filter--wrapper').classList.add('spacing');
                    document.querySelector('.filter--wrapper').classList.add('fixed');
                    if(document.querySelectorAll('.nav-icons .tooltip').length) {
                        document.querySelectorAll('.nav-icons .tooltip').forEach( el => {
                            el.classList.add('no-tooltip');
                        });
                    }
                }
            } else {
                flag = 1;
                navbar.classList.remove('sticky');
                header.style.marginBottom = 0;
                document.querySelector('.filter--wrapper').classList.remove('spacing');
                document.querySelector('.filter--wrapper').classList.remove('fixed');
                if(document.querySelectorAll('.nav-icons .tooltip').length) {
                    document.querySelectorAll('.nav-icons .tooltip').forEach( el => {
                        el.classList.remove('no-tooltip');
                    });
                }
            }
        } else {
            const deltaTop = window.scrollY;
            //const screenWidth = window.innerWidth,
                
    
            
            if (deltaTop > navbarTop) {// && screenWidth >= 1080) {
                navbar.classList.add('sticky');
                header.style.marginBottom = navbarHeight + 'px';
                return;
            }
            navbar.classList.remove('sticky');
            header.style.marginBottom = 0;
        }
    });


    const passwordResetForm = document.querySelector('#tab-forgot-password');
    passwordResetForm.addEventListener('submit', function (e) {
        e.preventDefault();
        //     const formData = new FormData(this);
        //     const url = this.getAttribute('action');
        //     const method = this.getAttribute('method');
        //     fetch(url, {
        //         method: method,
        //         body: formData
        //     }).then(response => response.json()).then(data => {
        //         // console.log(data);
        //         if (data.success) {
        const successTab = document.querySelector('#confirmation-tab');
        const allTabs = document.querySelectorAll('.tab');
        allTabs.forEach(el => successTab == el ? el.classList.add('active') : el.classList.remove('active'));

        //         }
        //     });
    });

    const multimedia = document.querySelectorAll('.multimedia');
    multimedia.forEach((item) => {
        const video = item.querySelector('video');
        const playButton = item.querySelector('.play-button');
        playButton.addEventListener('click', (e) => {
            e.preventDefault();
            video.play();
            playButton.style.display = 'none';
        });
        video.addEventListener('ended', () => {
            playButton.style.display = 'block';
        });
        video.addEventListener('pause', () => {
            playButton.style.display = 'block';
        });
        video.addEventListener('play', () => {
            playButton.style.display = 'none';
        });
    });

    const dropdownBodySelectors = ['sort-list', 'filter-modal--content', 'modal', 'lightbox-single', 'object-lightbox'];
    dropdownBodySelectors.forEach(selector => {
        wrapper = document.querySelectorAll(`.${selector}`);
        if (!wrapper) return;
        wrapper.forEach(el => DesigateAccessible(false, el));
    });

    // datetimeSetup();
});

const splitThrice = (arr) => {

    const defaultSize = Math.floor(arr.length / 3);
    const mod = arr.length % 3;
    const sizes = [defaultSize, defaultSize, defaultSize];
    sizes[0] += mod >= 1 ? 1 : 0;
    sizes[1] += mod == 2 ? 1 : 0;

    const first = arr.slice(0, sizes[0]);
    const second = arr.slice(sizes[0], sizes[0] + sizes[1]);
    const third = arr.slice(sizes[0] + sizes[1]);

    return [first, second, third];
}

function userFav() {
    document.querySelectorAll('.user-fav').forEach(el => {
            
        el.addEventListener('click', (ev) => {
            if(el.classList.contains('fav-login')) {
                ev.preventDefault();
                ev.stopImmediatePropagation();
                
                const login = document.querySelector('#login-menu');
                login.dispatchEvent(new Event('open'));
                
            } else {
                const target = el;
                const url = target.dataset.postUrl;
                const targetName = target.dataset.postKey;
                const targetValue = target.dataset.postValue;

                let formData = new FormData();
                formData.append(targetName, targetValue);
                // console.log('url: ', url);

                fetch(url, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: formData
                })
                .then((response) => {
                    return response.json();
                })
                .then(data => {
                    if(data.status == 1) {
                        if(data.data == 'insert') {
                            let newUrl = url.split('/api/')[0]+'/api/delete_from_favourite';
                            // console.log('newUrl: ', newUrl);
                            target.dataset.postUrl = newUrl;
                            target.classList.remove('fav-add');
                            target.classList.add('fav-remove');
                            target.title = 'remove from collection';
                        } else if(data.data == 'delete'){
                            let newUrl = url.split('/api/')[0]+'/api/add_to_favourite';
                            target.dataset.postUrl = newUrl;
                            target.classList.remove('fav-remove');
                            target.classList.add('fav-add');
                            target.title = 'Add to collection';
                        }
                    }
                })
                .catch(error => {
                    console.log('error: ', error);
                });
            }
        });
    });
}

function datetimeSetup(targetLightbox) {
    const currentForm = targetLightbox.querySelector('form');
    const datetimeFields = currentForm.querySelectorAll('.input-group--datetime');

    if (datetimeFields.length) {

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        

        const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

        datetimeFields.forEach((datetime) => {
            const monthSelect = datetime.querySelector('.datetime-month');
            const daySelect = datetime.querySelector('.datetime-day');
            const yearSelect = datetime.querySelector('.datetime-year');

            // Initialize months
            const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            allMonths.forEach((monthName, i) => {
                const option = document.createElement('option');
                option.value = i + 1;
                option.innerHTML = monthName;
                if(monthSelect.querySelectorAll('option').length < allMonths.length) {
                    monthSelect.appendChild(option);
                }
                // monthSelect.appendChild(option);
            });
            monthSelect.value = currentMonth + 1;

            // Initialize years, e.g., from 1990 to currentYear
            for (let y = 2010; y <= currentYear + 6; y++) {
                const option = document.createElement('option');
                option.value = y;
                option.innerHTML = y;
                if(yearSelect.querySelectorAll('option').length <= (currentYear + 6 - 2010)) {
                    yearSelect.appendChild(option);
                }
                // yearSelect.appendChild(option);
            };
            yearSelect.value = currentYear;

            const initDays = (year, month) => {
                let daySelectValue = daySelect.value || currentDay;
                if(monthSelect.value == currentMonth + 1 && yearSelect.value == currentYear) {
                    daySelectValue = currentDay;
                }

                daySelect.innerHTML = '';  // Clear existing options
                const daysInMonth = getDaysInMonth(year, month);

                for (let d = 1; d <= daysInMonth; d++) {
                    const option = document.createElement('option');
                    option.value = d;
                    option.innerHTML = d;

                    if(daySelect.querySelectorAll('option').length <= daysInMonth) {
                        daySelect.appendChild(option);
                    }
                    // daySelect.appendChild(option);
                }
                daySelect.value = daySelectValue > daysInMonth ? daysInMonth : daySelectValue;
            };
            
            initDays(currentYear, currentMonth);

            // Update days when month or year changes
            monthSelect.addEventListener('change', () => initDays(parseInt(yearSelect.value), parseInt(monthSelect.value - 1)));
            

            yearSelect.addEventListener('change', () => initDays(parseInt(yearSelect.value), parseInt(monthSelect.value - 1)));
            
        });
    }
}

function closeFilterMobile(filter) {
    let currentFilter = filter;
    let currentTab = currentFilter.querySelector('.btn-close.active').dataset.tab;

    currentFilter.querySelector('.btn-close[data-tab="' + currentTab + '"]').addEventListener('click', () => {
        currentFilter.querySelector('.btn-close[data-tab="' + currentTab + '"]').classList.remove('active');
        currentFilter.querySelector('.filter-mobile--selection-list[data-tab="' + currentTab + '"]').classList.remove('show');
        currentFilter.classList.remove('open');
    });
}

function modalSideImage(target) {
    const imageWrapper = target.closest('.filter-modal--content').querySelector('.filter-modal--image');
    let imgSrc = target.dataset.image;
    let imgAlt = target.dataset.alt;
    if(imageWrapper) {
        imageWrapper.innerHTML = '<img src="' + imgSrc + '" alt="' + imgAlt + '">';

        setTimeout(() => {
            imageWrapper.style.opacity = 1;
        }, 100);
    }
}

function destroySideImage() {
    if (document.querySelectorAll('.filter-modal--image').length) {
        document.querySelectorAll('.filter-modal--image').forEach(el => {
            el.style.opacity = 0;
        });
    }
}

function lightboxOpen(target, type) {
    prevTab = event.target;

    if (type == 'modal') {
        // console.log('target: ', target);
        const targetLightbox = document.querySelector('.modal#modal-' + target);
        targetLightbox.classList.add('open');
        if(target == 'image-download') {
            imageDownload();
            formContents();
        }
        if(targetLightbox.querySelectorAll('form').length) {
            datetimeSetup(targetLightbox);
        }
        modalClose('lightbox', target);
    } else {
        lightboxClose(target);
        
    }

    let targetLightbox = document.querySelector('#' + target);
    targetLightbox.classList.add('show');
    
    DesigateAccessible(true, targetLightbox);

    const checkBox = [...targetLightbox.querySelectorAll('input[type="checkbox"], input[type="radio"]')];
    if(checkBox.length) {
        checkBox.forEach(el => {
            el.nextElementSibling.addEventListener('keydown', (e) => {
                if(e.key === 'Enter') {
                    el.checked = !el.checked;
                }
            });

            el.addEventListener('keydown', (e) => {
                if(e.key === 'Enter') {
                    el.click();
                }
            });
        });
    }
    if(targetLightbox.querySelectorAll('.dropdown .sort-list').length) {
        targetLightbox.querySelectorAll('.dropdown .sort-list').forEach(el => {
            DesigateAccessible(false, el);
        });
    }
}

function lightboxClose(target) {
    let targetLightbox = document.querySelector('#' + target);
    targetLightbox.querySelector('.lightbox-close').addEventListener('click', () => {
        targetLightbox.classList.remove('show');
        DesigateAccessible(false, targetLightbox);
    });

}

function imageDownload() {
    const imageDownloadForm = document.querySelector('#image-download');
    if(imageDownloadForm.querySelectorAll('[data-image-download-show-section-when-clicked=true]').length) {
        imageDownloadForm.querySelectorAll('[data-image-download-show-section-when-clicked=true]').forEach(el => {
            const targetTab = el;
            targetTab.querySelectorAll('input, label').forEach(el => {
                el.addEventListener('click', () => {
                    let targetTabFlag = document.querySelectorAll('[data-image-download-show-section-when-clicked=true] input:checked').length;
                    if(targetTabFlag) {
                        document.querySelectorAll('[data-section-flag]').forEach(el => {
                            el.dataset.imageDownloadShowSection = 'true';
                        });
                    } else {
                        document.querySelectorAll('[data-section-flag]').forEach(el => {
                            el.dataset.imageDownloadShowSection = 'false';
                        });
                    }
                    
                    formContents();
                });
            });
        });
    }
}

function formContents() {
    if(document.querySelectorAll('[data-section-flag]').length) {
        document.querySelectorAll('[data-section-flag]').forEach(el => {
            let targetContent = el.closest('.lightbox-form--content');
            let sectionFlag = el.dataset.imageDownloadShowSection;

            if(sectionFlag == 'false') {
                targetContent.style.display = 'none';
            }
            if(sectionFlag == 'true') {
                targetContent.style.display = '';
            }
        })
    }
}

function copyURL(target) {
    let titleOriginal = target.title;
    let tempInput = document.createElement('input');
    let url = window.location.href;

    if (target.dataset.permaUrl) {
        url = target.dataset.permaUrl;
    }

    document.body.appendChild(tempInput);
    tempInput.value = url;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    target.title = 'copied';
    if (target.classList.contains('addthis_button_copy_link')) {
        target.classList.add('copied');
    }

    setTimeout(() => {
        target.title = titleOriginal;
        if (target.classList.contains('addthis_button_copy_link')) {
            target.classList.remove('copied');
        }
    }, 5000);
}

function calendar(target) {
    // // console.log('target: ', target);
    const calendar = target;
    const filter = calendar.dataset.selectedDate;

    
    let filteredDate = '';

    const isFilterSetToAValidDate = filter && filter !== 'current_week';

    if (isFilterSetToAValidDate) {
        const [filterYear, filterMonth, filterDay] = filter.split('-');
        filteredDate = new Date(filterYear, filterMonth - 1, filterDay);
    }
    
    const today = new Date();
    
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    // let disabledDates = target.dataset.disabledDates;
    // // // console.log('disabledDates: ', disabledDates);
    // disabledDates = JSON.parse(disabledDates);

    const enabledDatesRaw = target.dataset.enabledDates;
    // // console.log('enabledDates: ', enabledDates);
    const enabledDates = JSON.parse(enabledDatesRaw) || [];

    if(isFilterSetToAValidDate) {
        showCalendar(filteredDate.getMonth(), filteredDate.getFullYear());
    } else {
        showCalendar(currentMonth, currentYear);
    }

    function showCalendar(month, year) {
        const startDate = filteredDate ? filteredDate : today;
        let firstDay = (new Date(year, month)).getDay();
        let daysInMonth = 32 - new Date(year, month, 32).getDate();

        let calendarBody = calendar.querySelector('.calendar-body');
        calendarBody.innerHTML = '';

        calendar.querySelector('.monthAndYear').innerText = months[month] + ' ' + year;
        calendar.querySelector('.monthAndYear').dataset.month = month;
        calendar.querySelector('.monthAndYear').dataset.year = year;

        if(location.href.indexOf('filter/past') < 0) {
            if (month <= currentMonth && year <= currentYear) {
                calendar.querySelector('.calendar-prev').classList.add('btn-disabled');
            } else {
                calendar.querySelector('.calendar-prev').classList.remove('btn-disabled');
            }
        } else {
            if ((month >= currentMonth && year == currentYear) || year < currentYear) {
                calendar.querySelector('.calendar-next').classList.add('btn-disabled');
            } else {
                calendar.querySelector('.calendar-next').classList.remove('btn-disabled');
            }
        }

        let date = 1;
        let spare = 1; 

        for (let i = 0; i < 6; i++) {
            let row = document.createElement('tr');
            for (let j = 0; j < 7; j++) {

                if (i === 0 && j < firstDay) {
                    let prevMonth = 32 - new Date(year, month - 1, 32).getDate();
                    let cell = document.createElement('td');
                    let cellText = document.createTextNode(prevMonth - firstDay + 1 + j);

                    cell.appendChild(cellText);
                    row.appendChild(cell);
                } else if (date > daysInMonth) {
                    let cell = document.createElement('td');
                    let cellText = document.createTextNode('0' + spare);

                    if (spare > 9) {
                        cellText = document.createTextNode(spare);
                    }
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                    spare++;
                } else {
                    let cell = document.createElement('td');
                    let cellText = document.createTextNode(date);
                    
                    if (date < 10) {
                        cellText = document.createTextNode('0' + date);
                    }
                    if(date == startDate.getDate() && year == startDate.getFullYear() && month == startDate.getMonth()) {
                        cell.classList.add("bg-info");
                    }
                    const to2Digit = (num) => (num < 10 ? '0' : '' ) + `${num}`;

                    const YYYYmmdd = `${year}-${to2Digit(month + 1)}-${to2Digit(date)}`;

                    

                    // let disabledDate = false;
                    
                    // disabledDate = (date < startDate.getDate() && month <= startDate.getMonth() && year <= startDate.getFullYear()) ? true : false;

                    const isDateEnabled = enabledDates.includes(YYYYmmdd) || calendar.classList.contains('freeform');
                    console.log('isDateEnabled: ', isDateEnabled);
                    
                    if (!isDateEnabled) {
                        cell.classList.add("excluded");
                    } else {
                        cell.classList.add('selectable');
                        cell.setAttribute('tabindex', -1);
                    }

                    cell.appendChild(cellText);
                    // if(location.href.indexOf('filter/past') < 0) {
                    //     if (date >= today.getDate() || month > today.getMonth()) {
                    //         cell.classList.add('selectable');
                    //         cell.setAttribute('tabindex', -1);
                    //     }
                    // } else {
                    //     if ((date < today.getDate() && month == today.getMonth()) || month < today.getMonth()) {
                    //         cell.classList.add('selectable');
                    //         cell.setAttribute('tabindex', -1);
                    //     }
                    // }
                    
                    // for (let i = 0; i < disabledDates.length; i++) {
                    //     let disabledDate = disabledDates[i];
                    //     let targetDate = Number(disabledDate.split('-')[2]);
                    //     let targetMonth = Number(disabledDate.split('-')[1]);
                    //     let targetYear = Number(disabledDate.split('-')[0]);

                    //     if (date === targetDate && year === targetYear && month + 1 === targetMonth) {
                    //         cell.classList.add("excluded");
                    //         cell.classList.remove('selectable');
                    //         cell.removeAttribute('tabindex');
                    //     }
                    // }

                    

                    row.appendChild(cell);
                    date++;
                }
            }
            calendarBody.appendChild(row); // appending each row into calendar body.
            if(i == 5) {
                dateSelect();
            }
        }
    }

    calendar.querySelector('.calendar-prev').addEventListener('click', () => {
        if (!calendar.querySelector('.calendar-prev').classList.contains('btn-disabled')) {
            let month = Number(calendar.querySelector('.monthAndYear').dataset.month);
            let year = Number(calendar.querySelector('.monthAndYear').dataset.year);
            if (month == 0) {
                month = 11;
                year--;
            } else {
                month--;
            }
            showCalendar(month, year);
            DesigateAccessible(true, calendar);
            
        }
    });
    calendar.querySelector('.calendar-next').addEventListener('click', () => {
        let month = Number(calendar.querySelector('.monthAndYear').dataset.month);
        let year = Number(calendar.querySelector('.monthAndYear').dataset.year);
        if (month == 11) {
            month = 0;
            year++;
        } else {
            month++;
        }
        showCalendar(month, year);
        DesigateAccessible(true, calendar);
    });

    // if (calendar.querySelectorAll('.selectable').length) {
    //     calendar.querySelectorAll('.selectable').forEach(el => {
    //         el.addEventListener('click', () => {
    //             let selectedDate = el.textContent;
    //             let selectedMonth = calendar.querySelector('.monthAndYear').dataset.month;
    //             let selectedYear = calendar.querySelector('.monthAndYear').dataset.year;
    //             el.closest('.input-group').querySelector('input').value = Number(selectedMonth) + 1 + '/' + selectedDate + '/' + selectedYear;
    //         });
    //     });
    // }
}

function dateSelect() {
    document.querySelectorAll('.calendar-body .selectable').forEach(el => {
        el.addEventListener('click', () => {
            selectable(el);
        });

        el.addEventListener('keypress', (e) => {
            if(e.key === 'Enter') {
                selectable(el);
            }
        });

        function selectable(el) {
            let selectedValue = el.textContent;
            let currentMonth = Number(el.closest('.calendar').querySelector('.monthAndYear').dataset.month) + 1;
            let currentYear = el.closest('.calendar').querySelector('.monthAndYear').dataset.year;
            let isFilter = el.closest('.filter');
            if (isFilter) {
                //date search filter function call
                if(currentMonth < 10) {
                    currentMonth = '0'+currentMonth;
                }
                let dateSet = currentYear + '-' + currentMonth +'-' + selectedValue;
                let url = location.href.split('#main')[0].split('/date/')[0]+'/date/'+dateSet;
                if(document.querySelector('.filter--overlay.show')) {
                    document.querySelector('.filter--overlay.show').click();
                }
                window.location.href = url;
            } else {
                //set up the selected date value to the input field
                const input = el.closest('.input-group').querySelector('input');
                input.value = currentMonth + '/' + selectedValue + '/' + currentYear;
                
                document.querySelectorAll('.calendar-body .selectable').forEach(l =>
                    l.classList.remove('bg-info')
                );
                el.classList.add('bg-info');
                dropdownClose();
            }
        }
    });
}

function share() {
    let target = document.querySelector('.social-wrapper')
    document.querySelector('#modal-social').classList.add('open');
    target.classList.add('show');
    DesigateAccessible(true, target);
    modalClose('social', 'social');

}

function modalClose(kind, target) {
    let modal = document.querySelector('#modal-'+target);

    modal.addEventListener('click', (e) => {
        // DesigateAccessible(false, modal)
        switch (kind) {
            case 'social':
                if (!e.target.closest('.accessible-link')) {
                    modal.classList.remove('open');
                    document.querySelector('.addthis_button_copy_link').classList.remove('copied');
                    document.querySelectorAll('.modal-content').forEach(el => {
                        el.classList.remove('show');
                        DesigateAccessible(false, modal);
                        document.querySelector('[onclick="share()"]').focus();
                    });
                }
                break;
            case 'lightbox':
                let closeTrigger = !e.target.classList.contains('lightbox') && !e.target.closest('.lightbox');

                if (closeTrigger || e.target.closest('.lightbox-close')) {
                    modal.classList.remove('open');
                    document.querySelector('.addthis_button_copy_link').classList.remove('copied');
                    document.querySelectorAll('.modal-content').forEach(el => {
                        el.classList.remove('show');
                        DesigateAccessible(false, modal);
                    });
                }
                break;
            default:
                document.querySelectorAll('.modal-content').forEach(el => {
                    el.classList.remove('show');
                    DesigateAccessible(false, modal)
                });
                break;
        }
    });
}

//share links
function shareLink(type, url, winWidth, winHeight) {
    let winTop = (screen.height / 2) - (winHeight / 2);
    let winLeft = (screen.width / 2) - (winWidth / 2);

    if (url == '') {
        url = window.location.href;
    }

    var link = '';
    var sharer = 'sharer';
    if (type == "facebook") {
        link = 'https://www.facebook.com/sharer.php?u=' + url;
    }
    if (type == "twitter") {
        link = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url);
        sharer = 'twitter-share-window';
    }
    window.open(link, sharer, 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
}

let globalLastLinkFocused;

function DesigateAccessible(isAccessible, element) {
    const focusables = [...element.querySelectorAll('a, button, input:not([type=hidden]), select, textarea, label.btn, td.selectable')];
    focusables.forEach(el => isAccessible ? el.setAttribute('tabindex', '0') : el.setAttribute('tabindex', '-1'));

    if (isAccessible){
        globalLastLinkFocused = document.activeElement;
        return focusables[0].focus();
    }
    
    //globalLastLinkFocused?.focus();
}

function authFormSubmit(url, targetForm) {
    let formData = new FormData();

    targetForm.querySelectorAll('input').forEach(el => {
        // console.log('el.name: ', el.name, ', el.value: ', el.value);
        formData.append(el.name, el.value);
    });

    // console.log('formData: ', formData);

    fetch(url, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: formData
    })
    .then((response) => {
        // console.log('response: ', response);
        return response.json();
    })
    .then(data => {
        // console.log('data: ', data);
        if(data.status == 0) {
            if (targetForm.querySelector('.form-error'))
                targetForm.querySelector('.form-error').textContent =  '* ' + data.message;
            if (targetForm.querySelector('.auth-message'))
                targetForm.querySelector('.auth-message').textContent =  '* ' + data.message;

            Object.keys(data.data).forEach(key => {
                let errorList = data.data[key];
                // console.log('errorList: ', errorList);
                if (targetForm.querySelector('input[name="'+key+'"]').closest('.input-group'))
                    targetForm.querySelector('input[name="'+key+'"]').closest('.input-group').classList.add('error');
                if (targetForm.querySelector('input[name="'+key+'"]').nextElementSibling)
                    targetForm.querySelector('input[name="'+key+'"]').nextElementSibling.textContent = '* ' + errorList;
            });
            targetForm.closest('.accordion')?.dispatchEvent(new Event('open'));
        } else {
            if (data.redirect) {
                window.location.href = data.redirect;
            }
            if(targetForm.id == 'tab-forgot-password') {
                let tabTitle = document.querySelector('.tab-forgotton .login-menu-tab').textContent;
                let tabDescription = document.querySelector('.tab-forgotton .tab-description').textContent;

                document.querySelector('.tab-forgotton .login-menu-tab').textContent = document.querySelector('.tab-forgotton .login-menu-tab').dataset.submitted;
                document.querySelector('.tab-forgotton .tab-description').textContent = document.querySelector('.tab-forgotton .tab-description').dataset.submitted;
                targetForm.querySelector('input').style.opacity = 0;
                targetForm.querySelector('.btn-submit').style.opacity = 0;

                setTimeout(() => {
                    document.querySelector('.tab-forgotton .login-menu-tab').textContent = tabTitle;
                    document.querySelector('.tab-forgotton .tab-description').textContent = tabDescription;
                    targetForm.querySelector('input').style.opacity = 1;
                    targetForm.querySelector('.btn-submit').style.opacity = 1;
                }, 10000);
            }
        }
    })
    .catch(error => {
        // console.log('error: ', error);
    });
}

// Get reCAPTCHA response from Google, add to defined form and call defined form function for submission
function applyGoogleReCAPTCHAAndSubmitForm(formFcn, url, targetForm) {
    const googleSiteKey = targetForm.querySelector('input[name="g_recaptcha_site_key"]') ? targetForm.querySelector('input[name="g_recaptcha_site_key"]').value : '';
    const googleAction = targetForm.querySelector('input[name="google_action"]') ? targetForm.querySelector('input[name="google_action"]').value : '';

    if (!grecaptcha || !googleSiteKey || !googleAction) {
        console.log('One or more google fields are missing');
        return false;
    }

    grecaptcha.ready(function() {
        grecaptcha.execute(googleSiteKey, {
            action: googleAction
        }).then(function(token) {
            if (!targetForm.querySelector('input[name="g-recaptcha-response"]')) {
                targetForm.insertAdjacentHTML('afterbegin', '<input type="hidden" name="g-recaptcha-response" value="">');
            }

            targetForm.querySelector('input[name="g-recaptcha-response"]').value = token;

            console.log('reCAPTCHA token: ' + token);

            // Call defined form submission function
            formFcn(url, targetForm);
        }).catch(error => {
            console.error("Error during submission:", error);
        });
    });
}


